export const UPSERT_WORK_ZONE = `
mutation upsertWorkZone($input:  UpsertWorkZoneInput!){
  upsertWorkZone(
    input:$input,
  ){
    __typename
    ...on WorkZone{
      id
      department_id
        carrier_id
      department{
        province_id
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
