import { Badge, Card } from 'antd';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import GraphqlService from '../../../../../services/graphql/GraphqlService';
import { INotification } from '../../../../../services/Notification';
import { NotificationType } from '../../../../../shared/EnumsValues';
import './index.less';

const NotificationCard = ({
  notification,
  deleteSelf,
}: {
  notification: INotification;
  deleteSelf: (notificationId: number) => void;
}) => {
  const [extraClassnames, setExtraClassnames] = useState('');
  const { Mutation, customRequest } = GraphqlService();
  const { push } = useHistory();

  const deleteNotification = useCallback(async () => {
    try {
      await customRequest({
        mutation: Mutation.deleteNotification,
        variables: {
          id: notification.id,
        },
      });
    } catch (error) {
      /* No mostramos error, continuamos con el flujo */
      console.error(error);
    }
  }, []);

  const handleNotificationAction = useCallback(() => {
    const redirectToTripDetailsPage = () => {
      push('/app/tripDetails', {
        applicationId: notification.application_id,
      });
    };

    const redirectToTripHistoryPage = () => {
      push('/app/tripHistory');
    };

    switch (notification.notification_type_id) {
      case NotificationType.NewTripApplication:
      case NotificationType.ApplicationRejected:
      case NotificationType.ApplicationAccepted:
      case NotificationType.ApplicationCompletedByCarrier:
      case NotificationType.ApplicationCompletedByPublisher:
      case NotificationType.TripCompleted:
        redirectToTripDetailsPage();
        break;
      case NotificationType.ApplicationExpired:
      case NotificationType.TripExpired:
        redirectToTripHistoryPage();
        break;
      default:
        break;
    }
  }, [notification.application_id, notification.notification_type_id]);

  const handleClick = useCallback(async () => {
    setExtraClassnames('notification-card-clicked');
    try {
      await deleteNotification();
      handleNotificationAction();
      deleteSelf(notification.id);
    } catch (error) {
      /* No mostramos error, continuamos con el flujo */
      console.error(error);
    }
  }, [
    deleteNotification,
    deleteSelf,
    handleNotificationAction,
    notification.id,
  ]);

  return (
    <Card
      onClick={handleClick}
      className={`notification-card ${extraClassnames}`}
      size="small"
    >
      <div className="notification-card-dot">
        <Badge color="#A83C16"></Badge>
      </div>
      <div className="notification-card-content">
        <div className="notification-card-title">
          <b>{notification.title}</b>
        </div>
        <div className="notification-card-description">
          {notification.description}
        </div>
      </div>
    </Card>
  );
};

export default NotificationCard;
