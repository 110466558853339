import { Avatar, Dropdown } from 'antd';
import { useContext } from 'react';

import { ContextApp } from '../../../../shared';

import './index.less';

export const AvatarComponent = () => {
  const { user, pictureProfileUrl } = useContext(ContextApp);

  const userMenu = <></>;

  return user?.business_name ? (
    <>
      <div className="AvatarComponent">
        <Dropdown overlay={userMenu} trigger={['click']}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className="ant-dropdown-link"
            style={{ cursor: 'none' }}
            onClick={(e) => e.preventDefault()}
          >
            <Avatar
              className="Avatar"
              src={pictureProfileUrl ? pictureProfileUrl : undefined}
            >
              {user?.business_name[0].toUpperCase()}
            </Avatar>
            <span
              className="AvatarName"
              style={{ fontWeight: 'bold', marginLeft: '5px' }}
            >
              {user?.business_name ? user?.business_name : user?.username}
            </span>
          </a>
        </Dropdown>
      </div>
    </>
  ) : null;
};
