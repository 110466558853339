export const UNLOAD_TYPES = `query unload_types(
  $searchText: String
  $filter: FilterUnloadTypeInput
  $orderBy: UnloadTypeOrderInput
  $skip: Int
  $take: Int
) {
  unload_types(
    searchText: $searchText
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on UnloadType {
      id
      name
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
