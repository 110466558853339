export const CREATE_TRUCK = `
mutation createTruck($input: CreateTruckInput!){
	createTruck(
		input: $input
	){
		__typename
		...on Truck{
			id
			license_plate{
        id
        value
        configuration_license_plate{
          position
        }
      }
			mark
			model
			model
			year
			max_load_tn
			unload_type_id
      truck_picture{
        id          
      }
		}
		...on ResultError{
			status_code
				message
		}
		
	}
}
`;

export const UPDATE_TRUCK = `
mutation updateTruck($input: UpdateTruckInput!){
    updateTruck(
      input: $input
    ){
			__typename
      ...on Truck{
        id
        license_plate{
          id
          value
          configuration_license_plate{
            position
          }
        }
        mark
        model
        model
        year
        max_load_tn
        unload_type_id
        truck_picture{
          id          
        }
      }
      ...on ResultError{
        status_code
          message
      }
      
    }
  }
`;

export const DELETE_TRUCK = `
mutation deleteTruck($input: DeleteTruckInput!){
    deleteTruck(
      input: $input
    ){
			__typename
      ...on Truck{
        id
        license_plate{
          id
          value
          configuration_license_plate{
            position
          }
        }
        mark
        model
        model
        year
        max_load_tn
        unload_type_id
        truck_picture{
          id          
        }
      }
      ...on ResultError{
        status_code
          message
      }
      
    }
  }
`;

export const CREATE_TRUCK_PICTURE = `
mutation createTruckPicture(
  $truck_id: Int!, $file:  Upload!
){
  createTruckPicture(
    truck_id:$truck_id,
    file:$file,
    
  ){
    __typename
    ...on TruckPicture{	
    filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
  
}
`;

export const UPDATE_TRUCK_PICTURE = `
mutation updateTruckPicture($truck_id: Int!,$file: Upload!, $current_truck_picture_id:Int!){
  updateTruckPicture(truck_id:$truck_id, file:$file, current_truck_picture_id:$current_truck_picture_id){
    __typename
    ...on TruckPicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}`;
