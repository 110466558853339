export const CREATE_TRAILER = `mutation createTrailer($input: CreateTrailerInput!){
	createTrailer(
		input: $input
	){
		__typename
		...on Trailer{
			id
			license_plate
			mark
			model
			model
			year
			max_load_tn
			unload_type_id
      trailer_picture{
        id          
      }
		}
		...on ResultError{
			status_code
				message
		}
		
	}
}
`;

export const UPDATE_TRAILER = `mutation updateTrailer($input: UpdateTrailerInput!){
    updateTrailer(
      input: $input
    ){
			__typename
      ...on Trailer{
        id
        license_plate
        mark
        model
        model
        year
        max_load_tn
        unload_type_id
        trailer_picture{
          id          
        }
      }
      ...on ResultError{
        status_code
          message
      }
      
    }
  }
`;

export const DELETE_TRAILER = `mutation deleteTrailer($input: DeleteTrailerInput!){
    deleteTrailer(
      input: $input
    ){
			__typename
      ...on Trailer{
        id
        license_plate
        mark
        model
        model
        year
        max_load_tn
        unload_type_id
        trailer_picture{
          id          
        }
      }
      ...on ResultError{
        status_code
          message
      }
      
    }
  }
`;

export const CREATE_TRAILER_PICTURE = `mutation createTrailerPicture(
  $trailer_id: Int!, $file:  Upload!
){
  createTrailerPicture(
    trailer_id:$trailer_id,
    file:$file,
    
  ){
    __typename
    ...on TrailerPicture{	
    filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
  
}
`;

export const UPDATE_TRAILER_PICTURE = `mutation updateTrailerPicture($trailer_id: Int!,$file: Upload!, $current_trailer_picture_id:Int!){
  updateTrailerPicture(trailer_id:$trailer_id, file:$file, current_trailer_picture_id:$current_trailer_picture_id){
    __typename
    ...on TrailerPicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
