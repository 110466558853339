import { NavContext } from '@ionic/react';
import { useContext, useEffect } from 'react';
import { ContextApp } from '..';
import { Authorization } from '../Authorization';
import { EnumsValues } from '../EnumsValues';

export const useCheckPermissionForBackOfficeLayout = () => {
  const { user, functions } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);

  useEffect(() => {
    if (
      !Authorization.security(functions, EnumsValues.Functions.BackOfficeLayout)
    ) {
      navigate('/app/home');
      return;
    }
  }, [user]);

  return;
};
