export const CREATE_TRIP = `
mutation createTrip($input: CreateTripInput!){
  createTrip(input:$input) {
    __typename
    ... on Trip {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_TRIP = `
mutation updateTrip($input: UpdateTripInput!) {
  updateTrip(input: $input) {
    __typename
    ... on  Trip{
     id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_TRIP = `
mutation deleteTrip($id:Int!) {
  deleteTrip(id: $id) {
    __typename
    ... on Trip {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DISABLE_TRIP = `
mutation disabledTrip($trip_id:Int!) {
  disabledTrip(trip_id: $trip_id) {
    __typename
    ... on Trip {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const ACTIVE_TRIP = `
mutation activeTrip($trip_id:Int!) {
  activeTrip(trip_id: $trip_id) {
    __typename
    ... on Trip {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
