import { createContext } from 'react';
import { IConfigApp, IConfig, IEnvironment } from '../services/environment';
import { IUser, IUserFirebase } from '../services/user';
import { IRole } from '../services/role';
import { Language } from '../services/Language';
const configApp: any = {};
const environment: any = {};
const config: any = {};
const roles: any[] = [];
const functions: string[] = [];

interface IContextApp {
  configApp: IConfigApp;
  setUser: Function;
  user?: IUser;
  setLoading: Function;
  loading: boolean;
  setLoadingMessage: Function;
  loadingMessage: string;
  setShowLoading: Function;
  showLoading: boolean;
  environment: IEnvironment;
  setEnvironment: Function;
  config: IConfig;
  setConfig: Function;
  roles: IRole[];
  setRoles: Function;
  functions: string[];
  setFunctions: Function;
  dataLoaded: boolean;
  setDataLoaded: Function;
  checkAuth: () => Promise<void>;
  setAuth: Function;
  mainMenuCollapsed: boolean;
  setMainMenuCollapsed: Function;
  menuBreakpointCollapsed: boolean;
  setMenuBreakpointCollapsed: Function;
  sessionExpired: boolean;
  setSessionExpired: Function;
  languages: Language[];
  userDataFirebase?: IUserFirebase;
  setUserDataFirebase: Function;
  roleSelected: number | undefined;
  setRoleSelected: Function;
  formUserActive: boolean;
  setFormUserActive: Function;
  pictureProfileUrl: string;
  setPictureProfileUrl: Function;
  scrollableContainer: HTMLElement | null;
  setScrollableContainer: Function;
}

const context: IContextApp = {
  configApp,
  setUser: () => {},
  user: undefined,
  setLoading: () => {},
  loading: false,
  setLoadingMessage: () => {},
  loadingMessage: '',
  setShowLoading: () => {},
  showLoading: true,
  environment,
  setEnvironment: () => {},
  config,
  setConfig: () => {},
  setRoles: () => {},
  setFunctions: () => {},
  roles,
  functions,
  dataLoaded: false,
  setDataLoaded: () => {},
  checkAuth: async () => {},
  setAuth: () => {},
  mainMenuCollapsed: false,
  setMainMenuCollapsed: () => {},
  menuBreakpointCollapsed: false,
  setMenuBreakpointCollapsed: () => {},
  sessionExpired: false,
  setSessionExpired: () => {},
  languages: [],
  setUserDataFirebase: () => {},
  userDataFirebase: undefined,
  roleSelected: undefined,
  setRoleSelected: () => {},
  formUserActive: true,
  setFormUserActive: () => {},
  pictureProfileUrl: '',
  setPictureProfileUrl: () => {},
  scrollableContainer: null,
  setScrollableContainer: () => {},
};

export const ContextApp = createContext(context);
