import React, { useContext } from 'react';
import { ContextApp } from '../../../shared';
import Menu from './assets/menu_white.svg';
import './index.less';

export const MenuTrigger = () => {
  const { mainMenuCollapsed, setMainMenuCollapsed } = useContext(ContextApp);
  function getStyles(): React.CSSProperties | undefined {
    if (mainMenuCollapsed) {
      return {
        left: 0,
      };
    }
    return {
      left: 200,
    };
  }
  return (
    <div className="menuTrigger" style={getStyles()}>
      <button
        className="trigger"
        onClick={() => setMainMenuCollapsed(() => !mainMenuCollapsed)}
      >
        <img src={Menu} alt="Menu" height="auto" width="auto" />
      </button>
    </div>
  );
};
