import { message, Modal, Collapse } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import mergeImages from 'merge-images';
import LogoRodar from '../layouts/public/assets/RODAR LOGO PNG.png';
import DomToImage from 'dom-to-image';

export const MAX_SIZE_IMAGE_IN_MB = 10;
export const PREFIX_LEGAL_NUMBER = 20110;
export const SECONDS_CONSTANT = 1000;

const Tools = () => {};
// @TODO: esto debe ir en el .env
const ROLE_ADMINISTRADOR = 1;
const ROLE_CONFORMADOR = 2;
const ROLE_TRANSPORTISTA = 3;

const MESSAGE_CREATE_SUCCESS = 'Creado exitosamente';
const MESSAGE_CREATE_ERROR = 'Error al crear, intente nuevamente!';
const MESSAGE_UPDATE_SUCCESS = 'Actualizado exitosamente';
const MESSAGE_UPDATE_ERROR = 'Error al actualizar, intente nuevamente!';
const MESSAGE_DELETE_SUCCESS = 'Eliminado exitosamente';
const MESSAGE_DELETE_ERROR = 'Error al eliminar, intente nuevamente!';

const DEFAULT_TIMEOUT_LOADING = 150000;
const DEFAULT_TIMEOUT_MESSAGE = 1200;

/**
 * Iniciar un loading
 * @param msg
 */
Tools.messageLoading = (msg?: string, time?: number) => {
  message.destroy();
  message.loading(msg || 'Cargando...', time || DEFAULT_TIMEOUT_LOADING);
};

Tools.messageDestroy = (time?: number, callback?: Function) => {
  setTimeout(() => {
    message.destroy();
    if (callback) {
      callback();
    }
  }, time || DEFAULT_TIMEOUT_MESSAGE);
};

Tools.messageModalSuccess = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.success({
    content: msg,
  });
};

Tools.messageModalError = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.error({
    content: msg,
  });
};

Tools.messageModalInfo = (msg: string | JSX.Element) => {
  message.destroy();
  Modal.info({
    content: msg,
  });
};

/**
 * Resultado de un proceso de exito
 * @param msg
 */
Tools.messageSuccess = (msg: string, time?: number) => {
  message.destroy();
  message.success(msg);
  Tools.messageDestroy(time);
};

/**
 * Resultado de un proceso que dio error
 * @param msg
 */
Tools.messageError = (msg: string, time?: number) => {
  message.destroy();
  message.error(msg);
  Tools.messageDestroy(time);
};

/**
 * Para acciones de ABM
 */
Tools.messageCreating = (msg: string) => {
  Tools.messageLoading(`Creando ${msg} ...`);
};
Tools.messageUpdating = (msg: string) => {
  Tools.messageLoading(`Actualizando ${msg} ...`);
};
Tools.messageDeleting = (msg: string) => {
  Tools.messageLoading(`Eliminando ${msg} ...`);
};

/**
 * Para resultados de ABM
 */
Tools.messageCreateSuccess = () => {
  Tools.messageSuccess(MESSAGE_CREATE_SUCCESS);
};
Tools.messageCreateError = () => {
  Tools.messageError(MESSAGE_CREATE_ERROR);
};
Tools.messageUpdateSuccess = () => {
  Tools.messageSuccess(MESSAGE_UPDATE_SUCCESS);
};
Tools.messageUpdateError = () => {
  Tools.messageError(MESSAGE_UPDATE_ERROR);
};
Tools.messageDeleteSuccess = () => {
  Tools.messageSuccess(MESSAGE_DELETE_SUCCESS);
};
Tools.messageDeleteError = () => {
  Tools.messageError(MESSAGE_DELETE_ERROR);
};

Tools.isAdministrador = (user: any): boolean => {
  return Number(user.userRole?.id) === Number(ROLE_ADMINISTRADOR);
};

Tools.isConformador = (user: any): boolean => {
  return Number(user.userRole?.id) === Number(ROLE_CONFORMADOR);
};

Tools.isTransportista = (user: any): boolean => {
  return Number(user.userRole?.id) === Number(ROLE_TRANSPORTISTA);
};
Tools.isTransportistaByUserRoleId = (system_user_role_id: number): boolean => {
  return Number(system_user_role_id) === Number(ROLE_TRANSPORTISTA);
};

Tools.isCarrier = (carrierUser: any): boolean => {
  return carrierUser?.carrier && carrierUser?.carrier?.id;
};

/**
 * Set currency
 * TODO: system_settting for currency and locale and minimumFractionDigits
 */
Tools.formatCurrency = (val: any): string => {
  return new Intl.NumberFormat('es-CL', {
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'CLP',
  }).format(Number(val));
};

/**
 * Traduce el valor de ordenamiento de TablePro al valor que necesita el backend
 * @param data
 */
Tools.getTypeOrderByTableSortParam = (val: string) => {
  return val === 'ascend' ? 'asc' : 'desc';
};

Tools.getCarrierIdByUser = (carrierUser: any): number | null => {
  if (carrierUser && carrierUser.carrier && carrierUser.carrier.id) {
    return carrierUser.carrier.id;
  }
  return null;
};

/**
 * Retorna ArrayBuffer de un base64
 * @param base64
 */
Tools.base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64); // Comment this if not using base64
  const bytes = new Uint8Array(binaryString.length);
  return bytes.map((byte, i) => binaryString.charCodeAt(i));
};

/**
 * Retorna URL de un Blob
 * @param file
 */
Tools.createObjectURLOfBlob = (file: any) => {
  const arrayBuffer = Tools.base64ToArrayBuffer(file.img);
  const blob = new Blob([arrayBuffer], { type: file.mimetype });
  const fileURL: string = URL.createObjectURL(blob);
  return fileURL;
};

/**
 * Descarga archivo PDF
 * @param file
 * @param extension
 */
Tools.downloadFilePDF = (file: any, extension: string) => {
  const createAndDownloadBlobFile = (arrayBuffer: any, filename: string) => {
    const blob = new Blob([arrayBuffer]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.filename;
      link.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const arrayBuffer = Tools.base64ToArrayBuffer(file.img);
  createAndDownloadBlobFile(arrayBuffer, file.filename);
};

/**
 * Descarga archivo de imagen (png - jpeg - gif)
 * @param image
 */
Tools.downloadImage = (image: any) => {
  const link = document.createElement('a');
  link.href = `data:${image.mimetype};base64,${image.img}`;
  link.download = image.filename;
  link.click();
};

Tools.filter = (list: any[], search: string) => {
  if (search) {
    const result: any[] = list.filter((item) => {
      let itemLower = JSON.stringify(item);
      itemLower = itemLower.toLowerCase();
      const searchSplit: any[] = search.toLowerCase().split(' ');
      let validate = 0;
      searchSplit.forEach((search) => {
        if (itemLower.indexOf(search) !== -1) {
          validate += 1;
        }
      });
      if (validate === searchSplit.length) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  } else {
    return list;
  }
};

Tools.fileWeightOfSize = (size: number): number => {
  //lo paso a kbyte
  const kbyte = size / 1000;
  //lo paso a megas
  const mega = kbyte / 1000;
  //redondeo a un decimal
  return Number(mega.toFixed(2));
};

Tools.capitalize = (text: string): string => {
  if (text.length > 1) {
    const firstCharacter = text.charAt(0);
    return `${firstCharacter.toUpperCase()}${text.substring(1)}`;
  }
  return text;
};

Tools.stripPaginationVariables = (variables: any) => {
  const returningVariables = { ...variables };
  delete returningVariables.skip;
  delete returningVariables.take;
  return returningVariables;
};

Tools.getSkip = (pageSize: number | undefined, current: number | undefined) => {
  if (!pageSize || !current) {
    return 0;
  }
  return pageSize * (current - 1);
};

Tools.blobToUrlBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const { Panel } = Collapse;
// Layout changes for mobile devices
// expandIconColumnIndex = index of the expandable column
Tools.setResponsiveTable = (expandIconColumnIndex?: number) => {
  if (
    window.screen.orientation.type === 'landscape-primary' ||
    window.screen.orientation.type === 'landscape-secondary'
  )
    return;

  let antProTable = document.querySelector('.ant-pro-table');

  antProTable?.classList.add('responsive-ant-pro-table');

  Tools.setResponsiveOrder();
  setTimeout(function () {
    Tools.setResponsiveLabel(expandIconColumnIndex);
    if (expandIconColumnIndex) {
      let rowExpandBtns = document.getElementsByClassName(
        'ant-table-row-expand-icon ',
      );
      if (rowExpandBtns !== null) {
        Array.from(rowExpandBtns).forEach(function (element) {
          element?.addEventListener('click', Tools.setResponsiveChildTable);
        });
      }
    }
  }, 0);
};

// set order columns in Collpase element
Tools.setResponsiveOrder = () => {
  let getTableHeader = document.querySelector(
    '.ant-pro-table .ant-table-thead',
  ) as HTMLElement;

  if (
    getTableHeader?.querySelectorAll('.ant-table-column-sorters').length === 0
  ) {
    return;
  }
  let responsiveCollapse = document.getElementById('responsive-collapse');

  if (responsiveCollapse !== null) {
    //ORDER UPDATE

    let contentCollapseSortHeader = document.getElementById(
      'contentCollapseSortHeader',
    )!;

    //1. Get shownSorter
    let shownSorter = contentCollapseSortHeader?.querySelector(
      '.ant-table-column-has-sorters',
    )!;

    //2. Get selectedSorted
    let selectedSorted = document.querySelectorAll(
      '.ant-collapse-content .ant-table-column-sort',
    );

    let elements: any = [];

    if (selectedSorted?.length === 0) {
      elements = shownSorter;
    } else {
      elements = Array.from(selectedSorted);
    }

    // 3. Set  CollapseSortContent
    let collapseContent = document.querySelector(
      '.ant-collapse-content .ant-collapse-content-box',
    )!;
    let collapseContentSort =
      collapseContent?.querySelector('.ant-table-thead');

    let collapseContentOptions =
      collapseContentSort?.getElementsByTagName('TR')[0];

    if (shownSorter !== null) {
      if (
        typeof shownSorter === 'object' &&
        shownSorter.nodeType !== undefined
      ) {
        collapseContentOptions?.appendChild(shownSorter);
      }
    }

    collapseContent?.appendChild(getTableHeader);

    contentCollapseSortHeader.innerHTML = '';

    if (elements !== null) {
      if (typeof elements === 'object' && elements.nodeType !== undefined) {
        contentCollapseSortHeader?.appendChild(
          elements[0] ? elements[0] : elements,
        );
      }
    }

    return;
  }

  if (getTableHeader) {
    //ORDER ADD
    let elements = [];
    let availableSorters = document.querySelectorAll(
      '.ant-table-column-has-sorters',
    );

    let selectedSorted = document.querySelectorAll('.ant-table-column-sort');
    if (selectedSorted?.length === 0) {
      elements = Array.from(availableSorters);
    } else {
      elements = Array.from(selectedSorted);
    }

    // 1. Set wrapper Collapse
    var parent = getTableHeader?.parentNode;
    var wrapper = document.createElement('div');
    wrapper?.setAttribute('id', 'responsive-collapse');
    parent?.replaceChild(wrapper, getTableHeader);

    // 2. Set  Collapse
    let contenedor = document.getElementById('responsive-collapse');
    wrapper?.setAttribute('id', 'responsive-collapse');
    var collapseElement = React.createElement(
      Collapse,
      { key: '1', expandIconPosition: 'right' },
      React.createElement(Panel, {
        header: React.createElement('div', { id: 'collapse-sort-header' }),
        key: '2',
        extra: '',
        forceRender: true,
      }),
    );
    ReactDOM.render(collapseElement, contenedor);
    var sortTitleCollapsesHeader = document.createElement('div');
    sortTitleCollapsesHeader?.setAttribute('id', 'sorTitle');
    sortTitleCollapsesHeader.innerHTML = `<span class='spanIcon' style='background: url("/assets/icon/swap-vertical-outline.svg") no-repeat'></span><span>Ordenar por</span>`;
    contenedor?.prepend(sortTitleCollapsesHeader);

    // 3. Set  CollapseSortHeader
    let collapseSortHeader = document.getElementById('collapse-sort-header')!;

    var contentCollapseSortHeader = document.createElement('div');
    contentCollapseSortHeader?.setAttribute('id', 'contentCollapseSortHeader');
    collapseSortHeader?.appendChild(contentCollapseSortHeader);

    // 4. Set  CollapseSortContent
    let collapseContent = document.querySelector(
      '.ant-collapse-content .ant-collapse-content-box',
    );
    collapseContent?.appendChild(getTableHeader);

    if (document.querySelectorAll('.ant-table-column-sorters').length === 1) {
      let icon = document.querySelector('.ant-collapse-arrow') as HTMLElement;
      icon.style.display = 'none';
    }
  }
};

// set Labels for elements
Tools.setResponsiveLabel = (expandIconColumnIndex?: number) => {
  setTimeout(function () {
    let getLabelsElements = document.querySelectorAll('th.ant-table-cell');
    let getLabels: any = [];

    //place  expandable column in the indicated index
    if (expandIconColumnIndex) {
      for (var i = 0; i < getLabelsElements.length; i++) {
        getLabels?.push(getLabelsElements[i]);

        if (
          getLabelsElements[i].classList.contains(
            'ant-table-row-expand-icon-cell',
          )
        ) {
          let columnExpanded = getLabelsElements[i];

          if (columnExpanded !== null) {
            getLabels?.splice(i, 1);
            getLabels?.splice(expandIconColumnIndex, 0, columnExpanded);
          }
        }
      }
    } else {
      getLabels = getLabelsElements;
    }

    let getRows = document.querySelectorAll('.ant-table-tbody .ant-table-row');
    if (!getLabels || !getRows) return;

    getRows.forEach((row, indexRow: number) => {
      const getCells = row?.querySelectorAll('.ant-table-cell');
      getCells.forEach((cell, index: number) => {
        let label = getLabels[index] ? getLabels[index].textContent : '';
        let id = label?.replace(/[^a-z0-9s]/gi, '') + '_' + indexRow;

        if (cell.querySelectorAll('.responsive-column-title').length > 0)
          return;

        let innerDiv = document.createElement('div');
        innerDiv.id = id;
        innerDiv.className = 'responsive-column-title';
        innerDiv.textContent = label + ': ';
        cell?.prepend(innerDiv);
      });
    });
  }, 0);
};
// set elements Labels in expandable table
Tools.setResponsiveChildTable = () => {
  setTimeout(function () {
    let getLabels = document.querySelectorAll(
      '.containterExpandable th.ant-table-cell',
    );
    let getRows = document.querySelectorAll(
      '.containterExpandable .ant-table-tbody .ant-table-row',
    );
    if (!getLabels || !getRows) return;

    getRows.forEach((row, indexRow: number) => {
      const getCells = row?.querySelectorAll('.ant-table-cell');
      getCells.forEach((cell, index: number) => {
        let label = getLabels[index] ? getLabels[index].textContent : '';
        let id = label?.replace(/[^a-z0-9s]/gi, '') + '_' + indexRow;

        if (cell?.querySelectorAll('.responsive-column-title').length > 0)
          return;

        let innerDiv = document.createElement('div');
        innerDiv.id = id;
        innerDiv.className = 'responsive-column-title';
        innerDiv.textContent = label + ': ';
        cell?.prepend(innerDiv);
      });
    });
  }, 0);
};

Tools.sortByZone = (a: any, b: any) => {
  let [ahh, amm] = a.label.split('GMT')[1].split(')')[0].split(':');
  let [bhh, bmm] = b.label.split('GMT')[1].split(')')[0].split(':');
  return +ahh * 60 + amm - (+bhh * 60 + bmm);
};
Tools.isDefined = (value: any) => value !== null && value !== undefined;

interface IGetUrlOfBase64FileParameter {
  mimetype: string;
  fileBase64: string;
}

Tools.getUrlOfBase64File = (fileData: IGetUrlOfBase64FileParameter): string => {
  return `data:${fileData.mimetype};base64,${fileData.fileBase64}`;
};

Tools.getBase64WithCallback = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

Tools.getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

Tools.getFileFromBase64 = async (
  fileBase64: string,
  name: string,
  extension: string,
  fileType: string,
) => {
  const response = await fetch(fileBase64);
  const blob = await response.blob();
  return new File([blob], `${name}.${extension}`, {
    type: fileType,
    lastModified: new Date().getTime(),
  });
};

/**
 * Devuelve un elemento HTML con la imagen en cuestión.
 * @param url
 * @returns
 */
Tools.loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = url;
  });
};

/**
 * Descarga un archivo codificado en base64
 * @param url
 * @returns
 */
Tools.downloadBase64 = (
  file: string,
  name: string,
  extension: string,
): void => {
  const anchor = document.createElement('a');
  anchor.href = file;
  anchor.download = `${name}.${extension}`;
  // Se simula el click para iniciar la descarga
  anchor.click();
};

/**
 * Devuelve una captura del elemento que utiliza la referencia pasada como parámetro
 * @param ref
 */
Tools.takeScreenshotWithRodarLogo = async (
  ref: React.MutableRefObject<any>,
  format: string = 'image/png',
) => {
  // Se setean dimensiones y padding para el logo, dimensiones originales: 1375x354
  const logoWidth = 138;
  const logoHeight = 35;
  const logoPadding = 18;

  // Se crea un elemento <a> para generar una descarga posteriormente
  const screenshotTarget = ref.current;
  const image = await DomToImage.toJpeg(screenshotTarget!, {
    bgcolor: 'white',
    // TODO: Se pueden agregar filtros segun los nodos que se quieran imprimir
  });

  // Se transforma la captura en base64 a un elemento imágen HTML
  const screenshotImage = await Tools.loadImage(image);
  const { width: screenshotWidth, height: screenshotHeight } = screenshotImage;

  // Se obtiene a partir de la url del logo, un elemento imágen HTML con la imágen del logo
  const logoRodarImage = await Tools.loadImage(LogoRodar);

  // Se redimensiona el logo. Se crea un canvas con las dimensiones de la sección que tomará el logo
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // Se setean dimensiones
  canvas.width = screenshotWidth;
  canvas.height = logoPadding + logoHeight + logoPadding;
  // Se setea el background a blanco
  ctx!.fillStyle = 'white';
  ctx!.fillRect(0, 0, canvas.width, canvas.height);
  // Se dibuja el logo con los offsets y dimensiones deseadas
  ctx!.drawImage(
    logoRodarImage,
    screenshotWidth / 2 - logoWidth / 2,
    logoPadding,
    logoWidth,
    logoHeight,
  );
  // Se obtiene el logo en base64
  const reducedLogoRodar = canvas.toDataURL();

  // Se mergea la screenshot junto con el logo. El logo estando por encima. Aunque no se deberían solapar.
  // Se setean offsets, opacidad y altura de la imagen final.
  return mergeImages(
    [
      { src: image, x: 0, y: logoPadding + logoHeight + logoPadding },
      {
        src: reducedLogoRodar,
        opacity: 1,
      },
    ],
    {
      format,
      height: logoPadding + logoHeight + logoPadding + screenshotHeight,
    },
  );
};

/**
 * Abre app de llamada con el telefono que se le pase por parametro
 * @param phone
 */
Tools.goToCallApp = (phone: string | number) => {
  const anchor = document.createElement('a');
  anchor.href = `tel:${phone}`;
  // Se simula el click para marcar el número a llamar
  anchor.click();
};

/**
 * Hace un scroll top del elemento html, que se pasa por parametro.
 * Si no se le pasa ningun elemento html lo hace directamente sobre window.
 * El segundo parametro es el behavior del scrollTo que admite 'auto' o 'smooth'
 *
 * @param elementWithScroll
 * @param behavior
 */
Tools.scrollTop = (
  elementWithScroll?: HTMLElement,
  behavior?: 'auto' | 'smooth',
) => {
  if (elementWithScroll) {
    elementWithScroll.scrollTo({
      top: 0,
      behavior: behavior || undefined,
    });
  } else {
    window.scrollTo({
      top: 0,
      behavior: behavior || undefined,
    });
  }
};

/**
 * Deshabilitado de autocomplete en inputs.
 * Puede servir para cuando el componente no tiene una prop que nos permita manejar el autocomplete.
 * Esta función se debe ejecutar en el evento onFocus del input pasandole el HTMLElement.
 *
 * @param htmlElement
 * @param autocomplete
 */
Tools.handleAutoComplete = (
  htmlElement: HTMLElement,
  autocomplete: boolean,
) => {
  if (htmlElement) {
    if (autocomplete) {
      htmlElement.setAttribute('autocomplete', 'on');
    } else {
      htmlElement.setAttribute('autocomplete', 'hard-disabled');
    }
  }
};

export { Tools };
