export const TRUCK_TYPES = `
query truckTypes(
    $searchText: String,
    $filter: FilterTruckTypeInput,
    $orderBy:  TruckTypeOrderInput,
    $skip: Int,
    $take: Int,
  ){ 
  truckTypes(
    searchText:$searchText,
    filter:$filter,
    orderBy:$orderBy,
    skip:$skip,
    take:$take
  ){
    __typename
    ...on TruckType{
      id
      name
      ref_icon
      configuration_license_plate{
        id
        label
        position
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
