export const USERS = `
query users(
  $filter:  FilterUserInput,
  $orderBy:  UserOrderInput,
  $searchText: String,
  $skip: Int,
  $take: Int
) {
  users(
    filter: $filter
    orderBy: $orderBy
    searchText:$searchText      
    skip: $skip
    take: $take
  ) {
    __typename
    ... on User {
      id
      username
      email
      firstname
      lastname
      active
      lastAccess
      language_id
      is_system_user
      user_role{
      role{
        name
        id
           }
      }
      profile{
        id
        phone
        timezone
      }
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const LOGIN = `mutation login($username: String!, $password: String!) {
  login(
    username: $username, password: $password
  ) 
  {
    __typename
    ...on Auth {
      token
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const LOGIN_FIREBASE = `mutation loginFirebase($uid: String!, $roleIdSelected: Int!) {
  loginFirebase(
    uid: $uid
   	roleIdSelected: $roleIdSelected
  ) 
  {
    __typename
    ...on Auth {
      token
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const AUTH = `
query authenticate {
  authenticate {
    __typename
    ... on AuthOutput {
      id
      username
      email
      roles {
        id
        name
      }
      permissions
      active
      uid
      carrier{
        id
        range_km
        validated
        accepted_terms_and_conditions
        work_zone {
          id
          department_id
          department{
            province_id
          }
        }
      }
      publisher {
        id
        validated
        accepted_terms_and_conditions
      }
      phone
      contact_phone
      business_name
     	email
      cuit_cuil
      profile_picture_id
      registered_profile
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const LOGOUT = `
  query {
    logout {
      __typename
      ... on LogoutOutput {
        result
      }
      ... on ResultError {
        status
        error_code
        message
        stack
        level
      }
    }
  }
`;

export const RESET_PASSWORD = `
  query resetPassword($username: String!) {
    __typename
    resetPassword(input: { username: $username }) {
      __typename
      result
    }
  }
`;

export const VERIFY_RECOVERY_PASSWORD_TOKEN = `
query verifyRecoveryPasswordToken($token: String!){
  verifyRecoveryPasswordToken(
    token: $token
  ){
    __typename
    ...on RecoveryResult{
      result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const USERS_COUNT = `
query userCount{
  userCount{
    __typename
    ... on UserCount{
      count
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;

export const USERS_COUNT_BY_ROLE = `
query userCountByRole{
  userCountByRole{
    __typename
    ... on UserCountByRole{
      role{
        id
        name
      }
      count
    }
  }
}
`;

export const GET_PROFILE_PICTURE = `
query getProfilePicture(
  $id:  Int!
){
  getProfilePicture(
    id: $id
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const GET_USER_CONTACT_DATA = `
query getUserContactData($input: UserInRoleIdInput!) {
  getUserContactData(input: $input) {
    __typename
    ... on UserContactData {
      id
      active
      email
      business_name
      contact_phone
      cuit_cuil
      profile_picture_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_USER_COMMENTS = `
query getUserComments($input: UserCommentsInput!, $skip: Int, $take: Int) {
  getUserComments(input: $input, skip: $skip, take: $take) {
    __typename
    ... on UserComments {
      experience {
        id
        name
      }
      comments_by_experience {
        date
        comments
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_USER_REPUTATION = `
query getUserReputation($input: UserInRoleIdInput!) {
  getUserReputation(input: $input) {
    __typename
    ... on UserReputation {
      experience {
        id
        name
      }
      count_by_experience
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
