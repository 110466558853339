export const VALIDATE_PUBLISHER = `
mutation validatePublisher{
    validatePublisher{
      __typename
      ...on Publisher{
        validated
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const PUBLISHER_ACCEPT_TERMS_AND_CONDITIONS = `
mutation publisherAcceptTermsAndConditions{
  publisherAcceptTermsAndConditions{
    __typename
    ...on Publisher{
      id
      accepted_terms_and_conditions
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
