import { Header } from 'antd/lib/layout/layout';
import './index.less';
import { MenuTrigger } from '../MenuTrigger';
import { useContext, useState } from 'react';
import { Authorization, ContextApp, EnumsValues } from '../../../shared';
import Notifications from './Notifications';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { isPlatform, NavContext } from '@ionic/react';
import { CounterOfTripsConfirmed } from './CounterOfTripsConfirmed/CounterOfTripsConfirmed';
import memorizeSegmentSelected from '../../../shared/memorizeSegmentSelected';
export const HeaderComponent = () => {
  const { user, functions } = useContext(ContextApp);
  const [profileRequired, setProfileRequired] = useState<boolean>(false);
  const { navigate } = useContext(NavContext);
  return (
    <Header className="HeaderComponent">
      {!profileRequired ? <MenuTrigger /> : null}
      {/* Este div habría que sacarlo luego y poner un BreadCrumb o usar algun estado del título actual de la página */}
      <div className="HeaderComponent__container-logo">
        <img
          alt="Rodar.app"
          src={`${process.env.PUBLIC_URL}/assets/rodar_logo_white.png`}
        />
      </div>
      <div className="HeaderComponent__container-buttons">
        <div style={{ display: 'flex' }}>
          <div>
            <Button type="text" onClick={() => window.location.reload()}>
              <ReloadOutlined style={{ color: 'white' }} />{' '}
              {isPlatform('desktop') ? (
                <span style={{ color: 'white' }}>Refrescar</span>
              ) : null}
            </Button>
          </div>
          {Authorization.security(
            functions,
            EnumsValues.Functions.QuantityOfTripsConfirmedRead,
          ) ? (
            <>
              <div style={{ marginRight: '15px' }}>
                <CounterOfTripsConfirmed
                  style={{ fontSize: 20, color: 'white' }}
                  onClick={(quantityOfTripsConfirmed) => {
                    if (quantityOfTripsConfirmed) {
                      memorizeSegmentSelected(
                        EnumsValues.IonSegmentsValues
                          .CarrierTripSegmentConfirmed,
                        'myTripsLastSegmentSelected',
                      );
                      navigate('/app/trips');
                    }
                  }}
                />
              </div>
            </>
          ) : null}
          {!Authorization.security(
            functions,
            EnumsValues.Functions.BackOfficeLayout,
          ) &&
          Authorization.security(
            functions,
            EnumsValues.Functions.NotificationRead,
          ) ? (
            <div>
              <Notifications />
            </div>
          ) : null}
        </div>
      </div>
    </Header>
  );
};
