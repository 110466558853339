export const VALIDATE_CARRIER = `
mutation validateCarrier{
    validateCarrier{
      __typename
      ...on Carrier{
        validated
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const CARRIER_ACCEPT_TERMS_AND_CONDITIONS = `
mutation carrierAcceptTermsAndConditions{
  carrierAcceptTermsAndConditions{
    __typename
    ...on Carrier{
      id
      accepted_terms_and_conditions
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
