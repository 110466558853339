export const DEPARTMENT = `
query department($id: Int!){
    department(
      id: $id
    ){
      __typename
      ...on Department{
        id
        name
        id_datagobar
        province_id
        lat
        lon
        province{
          id
          name
          id_datagobar
          country_id
          lat
          lon        
        }
      }
    	...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DEPARTMENTS = `
query departments(
    $searchText:String,
    $filter:  FilterDepartmentInput,
    $orderBy:  DepartmentOrderInput,
    $take: Int,
    $skip: Int,
  ){
    departments(
      searchText: $searchText,
      filter: $filter,
      orderBy: $orderBy,
      skip: $skip,
      take: $take,
    ){
      __typename
      ...on Department{
        id
        name
        id_datagobar
        province_id
        lat
        lon
        province{
          id
          name
          id_datagobar
          country_id
          lat
          lon        
        }
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }

`;
