import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.less';

import 'moment/locale/es';
import { renderRoutes } from 'react-router-config';
import routes from './routes';
import { ContextApp } from './shared';
import { StrictMode, useEffect, useState } from 'react';
import { IConfig, IConfigApp, IEnvironment } from './services/environment';
import configJson from './config.json';
import { IUser, IUserFirebase, UserService } from './services/user';
import { IRole } from './services/role';
import { PageLoading } from '@ant-design/pro-layout';
import { ConfigProvider } from 'antd';
import { Language } from './services/Language';
import esESIntl from 'antd/lib/locale/es_ES';

require('dotenv').config();

declare global {
  interface Window {
    firebaseui: any;
    firebase: any;
  }
}

const App: React.FC = () => {
  const [configApp] = useState<IConfigApp>(configJson);
  const [user, setUser] = useState<IUser | undefined>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [config, setConfig] = useState<IConfig>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [menuBreakpointCollapsed, setMenuBreakpointCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [languages, setLanguages] = useState<Language[]>([]);
  const [auth, setAuth] = useState<boolean>();
  const { authenticate } = UserService();
  const [userDataFirebase, setUserDataFirebase] = useState<
    IUserFirebase | undefined
  >(undefined);
  const [roleSelected, setRoleSelected] = useState<number | undefined>(
    undefined,
  );
  const [formUserActive, setFormUserActive] = useState<boolean>(true);
  const [pictureProfileUrl, setPictureProfileUrl] = useState<string>('');
  const [scrollableContainer, setScrollableContainer] =
    useState<HTMLElement | null>(null);

  const defaultEnvironmentCode = configJson.default_environment;
  const defaultEnvironment = (
    configJson.environments as {
      [key: string]: IEnvironment;
    }
  )[defaultEnvironmentCode];
  const defaultConfig = configJson.default_config;

  const checkAuth = async (): Promise<any> => {
    setAuth(() => false);
    setShowLoading(() => true);
    return authenticate()
      .then((res: any) => {
        let user: IUser;
        if (res.id) {
          user = {
            publisher: res.publisher,
            email: res.email,
            id: res.id,
            username: res.username,
            password: '',
            roles: res.roles,
            is_system_user: res.is_system_user,
            uid: res.uid,
            active: res.active,
            timezone: res.timezone,
            phone: res.phone,
            business_name: res.business_name,
            contact_phone: res.contact_phone,
            cuit_cuil: res.cuit_cuil,
            carrier: res.carrier,
            profile_picture_id: res.profile_picture_id,
            registered_profile: res.registered_profile,
          };
          setSessionExpired(false);
        }
        setUser(() => user);
        setRoles(() => res.roles);
        setFunctions(() => res.permissions);
        setAuth(() => true);
      })
      .catch((err) => {
        setAuth(() => true);
      })
      .finally(() => {
        setShowLoading(() => false);
      });
  };

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (!user && token) {
      checkAuth();
    } else {
      setShowLoading(() => false);
    }
  }, []);

  return (
    <ContextApp.Provider
      value={{
        configApp,
        user,
        setUser,
        setLoading,
        loading,
        loadingMessage,
        setLoadingMessage,
        setShowLoading,
        showLoading,
        environment: environment || defaultEnvironment,
        setEnvironment,
        roles,
        setRoles,
        functions,
        setFunctions,
        config: config || defaultConfig,
        setConfig,
        dataLoaded,
        setDataLoaded,
        checkAuth,
        setAuth,
        mainMenuCollapsed,
        setMainMenuCollapsed,
        menuBreakpointCollapsed,
        setMenuBreakpointCollapsed,
        sessionExpired,
        setSessionExpired,
        languages,
        userDataFirebase,
        setUserDataFirebase,
        roleSelected,
        setRoleSelected,
        formUserActive,
        setFormUserActive,
        pictureProfileUrl,
        setPictureProfileUrl,
        scrollableContainer,
        setScrollableContainer,
      }}
    >
      <IonApp>
        <ConfigProvider locale={esESIntl}>
          {!showLoading ? (
            <>
              <IonReactRouter>
                <IonRouterOutlet>
                  <StrictMode>{renderRoutes(routes)}</StrictMode>
                </IonRouterOutlet>
              </IonReactRouter>
            </>
          ) : (
            <PageLoading />
          )}
        </ConfigProvider>
      </IonApp>
    </ContextApp.Provider>
  );
};

export default App;
