import { BellOutlined } from '@ant-design/icons';
import { Badge, Popover, Spin } from 'antd';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import { INotification } from '../../../../services/Notification';
import NotificationCard from './NotificationCard';
import './index.less';
import usePushNotifications from '../../../../shared/hooks/usePushNotifications';
import pushNotificationsContext, {
  NotificationMessageHandler,
} from '../../../../shared/contexts/PushNotificationsContext';

const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const { addOnMessageHandlers, removeOnMessageHandlers } =
    usePushNotifications();
  const { canReceivePushNotifications } = useContext(pushNotificationsContext);

  useEffect(() => {
    const messageHandlers: NotificationMessageHandler[] = [
      {
        key: 'notificationBellHandler',
        handler: (payload) => {
          getData();
        },
      },
    ];

    if (canReceivePushNotifications) {
      addOnMessageHandlers(messageHandlers);
    }

    return () => {
      if (canReceivePushNotifications) {
        removeOnMessageHandlers(messageHandlers);
      }
    };
  }, [canReceivePushNotifications]);

  const { Query, customRequest } = GraphqlService();

  const getData = useCallback(async () => {
    setLoading(() => true);
    try {
      const data = await customRequest({
        query: Query.notifications,
        variables: {},
      });
      setNotifications(data);
    } catch (err: any) {
      console.error(err);
    }
    setLoading(() => false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const deleteSelf = (notificationId: number) => {
    setNotifications((oldNotifications) =>
      oldNotifications.filter(
        (notification) => notification.id !== notificationId,
      ),
    );
    handlePopoverVisibleChange(false);
  };

  const popoverContent = useMemo(() => {
    let innerComponent: JSX.Element | JSX.Element[];
    if (loading) {
      innerComponent = <Spin />;
    } else {
      innerComponent = notifications.length ? (
        notifications.map((notification) => {
          return (
            <NotificationCard
              deleteSelf={deleteSelf}
              notification={notification}
              key={notification.id}
            />
          );
        })
      ) : (
        <span>No hay notificaciones para mostrar</span>
      );
    }
    return <div className="notifications-popover">{innerComponent}</div>;
  }, [notifications, loading]);

  const handlePopoverVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible);
  };

  return (
    <>
      <Popover
        arrowPointAtCenter
        autoAdjustOverflow
        content={popoverContent}
        placement="bottomRight"
        trigger="click"
        overlayClassName="notification-popover-overlay"
        visible={popoverVisible}
        onVisibleChange={handlePopoverVisibleChange}
      >
        <Badge count={notifications.length} size="small">
          <BellOutlined
            style={{
              color: 'white',
              fontSize: 20,
              cursor: 'pointer',
            }}
          />
        </Badge>
      </Popover>
    </>
  );
};

export default Notifications;
