export const PROVINCES = `query provinces(
    $searchText:  String
    $filter: FilterProvinceInput
    $orderBy: ProvinceOrderInput
    $skip: Int
    $take: Int
  ){
    provinces(
      searchText:$searchText,
      filter:$filter,
      orderBy:$orderBy,
      skip:$skip,
      take:$take,      
    ){
      __typename
      ... on Province {
        id
        name
        id_datagobar
        lat
        lon
        country_id
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;
