import { AvatarComponent } from '../../HeaderComponent/AvatarComponent';
import './index.less';

export const LogoComponent = () => {
  return (
    <div className="LogoComponent">
      <AvatarComponent />
    </div>
  );
};
