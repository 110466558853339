import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { matchRoutes } from 'react-router-config';
import routes, { IRouteConfig } from '../../routes';

const useCurrentRoute = () => {
  const [matchedRoutes, setMatchedRoutes] = useState<IRouteConfig[]>([]);
  const location = useLocation();
  const leafRoute = useMemo(() => {
    return matchedRoutes.find((route) => !route.routes?.length);
  }, [matchedRoutes]);

  useEffect(() => {
    const matchingRoutes = matchRoutes(routes, location.pathname).map(
      (route) => route.route,
    );
    setMatchedRoutes(matchingRoutes);
  }, [location]);

  return {
    leafRoute,
    matchedRoutes,
  };
};

export default useCurrentRoute;
