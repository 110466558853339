export const DISTANCE = `
query tripDistance($input: TripDistanceInput!){
  tripDistance(input:$input) {
    __typename
    ... on TripDistance {
      distance_km
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
