import { EnumsValues } from './shared';
import {
  HomeOutlined,
  ProfileOutlined,
  SettingOutlined,
  ControlOutlined,
  CarOutlined,
} from '@ant-design/icons';
import React, { lazy, Suspense } from 'react';
import { RouteConfig } from 'react-router-config';
import { PageLoading } from '@ant-design/pro-layout';
import PublicLayout from './layouts/public';
import RegisteredLayout from './layouts/registered';
import RegisteredAdminLayout from './layouts/registeredAdmin';
const DefaultRedirect = lazy(
  () => import('./components/DefaultRedirect/DefaultRedirect'),
);
const Home = lazy(() => import('./components/Home/Home'));
const Perfil = lazy(() => import('./components/perfil/Perfil'));

const AppSetting = lazy(() => import('./components/AppSetting/AppSetting'));
const EmptyLayout = lazy(() => import('./layouts/emptyLayout'));
const Role = lazy(() => import('./components/Role/Role'));
const Permission = lazy(() => import('./components/Permission/Permission'));
const User = lazy(() => import('./components/User/User'));
const Login = lazy(() => import('./components/Login/Login'));
const CompleteProfile = lazy(
  () => import('./components/CompleteProfile/CompleteProfile'),
);
const WorkZone = lazy(() => import('./components/WorkZone/WorkZone'));
const CreateTrip = lazy(() => import('./components/CreateTrip/CreateTrip'));
const Truck = lazy(() => import('./components/Truck/Truck'));
const MyFleet = lazy(() => import('./components/MyFleet/MyFleet'));
const Profile = lazy(() => import('./components/Profile/Profile'));
const Trip = lazy(() => import('./components/Trip/Trip'));
const TripApplication = lazy(
  () => import('./components/TripApplication/TripApplication'),
);
const ApplicationList = lazy(
  () => import('./components/ApplicationList/ApplicationList'),
);
const TripDetails = lazy(() => import('./components/TripDetails/TripDetails'));
const DetailApplication = lazy(
  () => import('./components/DetailApplication/DetailApplication'),
);
const TripHistory = lazy(() => import('./components/TripHistory/TripHistory'));
const CarriersBackOffice = lazy(
  () => import('./components/CarriersBackOffice/CarriersBackOffice'),
);
const PublishersBackOffice = lazy(
  () => import('./components/PublishersBackOffice/PublishersBackOffice'),
);
const CompletedApplicationsList = lazy(
  () =>
    import(
      './components/ApplicationList/CompletedApplicationsList/CompletedApplicationsList'
    ),
);
const Help = lazy(() => import('./components/Help/Help'));
const TrucksInTrip = lazy(
  () => import('./components/TrucksInTrip/TrucksInTrip'),
);
const RegisterUser = lazy(
  () => import('./components/RegisterUser/RegisterUser'),
);
const SharedTrip = lazy(() => import('./components/SharedTrip/SharedTrip'));
const LandingPage = lazy(() => import('./components/LandingPage/LandingPage'));
const PublicTermsAndConditions = lazy(
  () =>
    import('./components/PublicTermsAndConditions/PublicTermsAndConditions'),
);
const RegisteredTermsAndConditions = lazy(
  () =>
    import(
      './components/RegisteredTermsAndConditions/RegisteredTermsAndConditions'
    ),
);

export interface IRouteConfig extends RouteConfig {
  authority?: string;
  routes?: IRouteConfig[];
  name?: string;
  icon?: React.ReactNode;
  path: string;
  hideInMenu?: boolean;
  requireRegister?: boolean;
  isStorablePath?: boolean;
}

const routes: IRouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: DefaultRedirect,
  },
  {
    path: '/app',
    component: RegisteredLayout,
    routes: [
      {
        path: '/app/home',
        component: Home,
        name: 'Inicio',
        requireRegister: true,
        icon: <HomeOutlined />,
      },
      {
        path: '/app/myProfile',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CompleteProfile viewEvaluation={true} />
          </Suspense>
        ),
        name: 'Mi perfil',
        requireRegister: true,
      },
      {
        path: '/app/createTrip',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CreateTrip />
          </Suspense>
        ),
        name: 'Crear viaje',
        hideInMenu: false,
        requireRegister: true,
        authority: EnumsValues.Functions.TripCreate,
      },
      {
        path: '/app/trips',
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <Trip {...props} />
          </Suspense>
        ),
        name: 'Mis viajes',
        hideInMenu: false,
        requireRegister: true,
      },
      {
        path: '/app/myFleet',
        component: () => (
          <Suspense fallback={PageLoading}>
            <MyFleet />
          </Suspense>
        ),
        name: 'Mi flota',
        authority: EnumsValues.Functions.TruckRead,
        requireRegister: true,
      },
      {
        path: '/app/workZone',
        component: () => (
          <Suspense fallback={PageLoading}>
            <WorkZone />
          </Suspense>
        ),
        name: 'Mi zona de trabajo',
        authority: EnumsValues.Functions.WorkZoneRead,
      },
      {
        path: '/app/tripHistory',
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <TripHistory {...props} />
          </Suspense>
        ),
        name: 'Historial de viajes',
        hideInMenu: false,
        requireRegister: true,
      },
      {
        path: '/app/termsAndConditions',
        component: () => (
          <Suspense fallback={PageLoading}>
            <RegisteredTermsAndConditions />
          </Suspense>
        ),
        name: 'Términos y condiciones',
        hideInMenu: false,
      },
      {
        path: '/app/editTrip/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CreateTrip />
          </Suspense>
        ),
        name: 'Crear viaje',
        hideInMenu: true,
        authority: EnumsValues.Functions.TripUpdate,
        requireRegister: true,
      },
      {
        path: '/app/tripApplication',
        component: () => (
          <Suspense fallback={PageLoading}>
            <TripApplication />
          </Suspense>
        ),
        name: 'Publicación de viaje',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/applications/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <ApplicationList />
          </Suspense>
        ),
        name: 'Postulaciones',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/applicationsCompleted/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CompletedApplicationsList />
          </Suspense>
        ),
        name: 'Postulaciones finalizadas',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/tripApplication/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <TripApplication />
          </Suspense>
        ),
        name: 'Publicación de viaje',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/tripDetails',
        component: () => (
          <Suspense fallback={PageLoading}>
            <TripDetails />
          </Suspense>
        ),
        name: 'Detalles del viaje',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/completeProfile',
        component: () => (
          <Suspense fallback={PageLoading}>
            <CompleteProfile />
          </Suspense>
        ),
        name: 'Completar perfil',
        hideInMenu: true,
      },
      {
        path: '/app/myReputationCarrier',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Profile type="carrier" title="Datos de mi perfil" />
          </Suspense>
        ),
        name: 'Mi reputación',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/myReputationPublisher',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Profile type="publisher" title="Datos de mi perfil" />
          </Suspense>
        ),
        name: 'Mi reputación',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/carrierProfile',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Profile type="carrier" title="Perfil del transportista" />
          </Suspense>
        ),
        name: 'Perfil del transportista',
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/publisherProfile',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Profile type="publisher" title="Perfil del dador de carga" />
          </Suspense>
        ),
        name: 'Perfil del dador de carga',
        hideInMenu: true,
      },
      {
        path: '/app/truck',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Truck />
          </Suspense>
        ),
        name: 'Camión',
        authority: EnumsValues.Functions.TruckCreate,
        hideInMenu: true,
      },
      {
        path: '/app/detailApplication:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <DetailApplication />
          </Suspense>
        ),
        name: 'Detalle de la aplicación',
        authority: EnumsValues.Functions.TruckCreate,
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/truckEdit/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Truck />
          </Suspense>
        ),
        name: 'Camión',
        authority: EnumsValues.Functions.TruckCreate,
        hideInMenu: true,
        requireRegister: true,
      },
      {
        path: '/app/administration',
        name: 'Administración',
        component: () => (
          <Suspense fallback={PageLoading}>
            <EmptyLayout />
          </Suspense>
        ),
        icon: <ControlOutlined />,
        //lo oculto porque por el momento no hay administrcion
        hideInMenu: true,
        routes: [
          {
            path: '/app/administration/role',
            component: () => (
              <Suspense fallback={PageLoading}>
                <Role />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Roles',
          },
          {
            path: '/app/administration/permission',
            component: () => (
              <Suspense fallback={PageLoading}>
                <Permission />
              </Suspense>
            ),
            authority: EnumsValues.Functions.PermissionList,
            name: 'Permisos',
          },
          {
            path: '/app/administration/user',
            component: () => (
              <Suspense fallback={PageLoading}>
                <User />
              </Suspense>
            ),
            authority: EnumsValues.Functions.UserRoleList,
            name: 'Usuarios',
          },
        ],
      },
      {
        path: '/app/perfil/:id',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Perfil />
          </Suspense>
        ),
        name: 'Perfil',
        authority: 'SinPermisoTest',
        icon: <ProfileOutlined />,
      },
      {
        path: '/app/help',
        component: () => (
          <Suspense fallback={PageLoading}>
            <Help />
          </Suspense>
        ),
        name: 'Ayuda',
        hideInMenu: false,
      },
      {
        path: '/app/trucksInTrip/:applicationId',
        hideInMenu: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <TrucksInTrip />
          </Suspense>
        ),
        name: 'Mi perfil',
        requireRegister: true,
      },
      {
        path: '/app/registerUser',
        hideInMenu: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <RegisterUser />
          </Suspense>
        ),
        name: 'Registrarse',
      },
      {
        path: '/app/trip/:id',
        hideInMenu: true,
        isStorablePath: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <SharedTrip />
          </Suspense>
        ),
        name: 'Viaje',
      },
    ],
  },
  {
    path: '/appAdmin',
    component: RegisteredAdminLayout,
    routes: [
      {
        path: '/appAdmin/home',
        component: () => <></>,
        name: 'Inicio',
        icon: <HomeOutlined />,
      },
      {
        path: '/appAdmin/administration',
        name: 'Administración',
        component: (props: any) => (
          <Suspense fallback={PageLoading}>
            <EmptyLayout {...props} />
          </Suspense>
        ),
        icon: <ControlOutlined />,
        routes: [
          {
            path: '/appAdmin/administration/carriers',
            authority: EnumsValues.Functions.CarrierRead,
            component: (props: any) => (
              <Suspense fallback={PageLoading}>
                <CarriersBackOffice {...props} />
              </Suspense>
            ),
            name: 'Transportistas',
            icon: <CarOutlined />,
          },
          {
            path: '/appAdmin/administration/publishers',
            authority: EnumsValues.Functions.PublisherRead,
            component: (props: any) => (
              <Suspense fallback={PageLoading}>
                <PublishersBackOffice {...props} />
              </Suspense>
            ),
            name: 'Dadores de carga',
            icon: <CarOutlined />,
          },
        ],
      },
      {
        path: '/appAdmin/setting',
        authority: EnumsValues.Functions.AppSettingRead,
        component: () => (
          <Suspense fallback={PageLoading}>
            <AppSetting />
          </Suspense>
        ),
        name: 'Configuración',
        icon: <SettingOutlined />,
      },
    ],
  },
  {
    path: '/',
    component: PublicLayout,
    routes: [
      {
        path: '/termsAndConditions',
        component: () => (
          <Suspense fallback={PageLoading}>
            <PublicTermsAndConditions />
          </Suspense>
        ),
        name: 'Términos y condiciones',
        hideInMenu: false,
        exact: true,
      },
      {
        path: '/landingPage',
        component: () => (
          <Suspense fallback={PageLoading}>
            <LandingPage />
          </Suspense>
        ),
        name: 'Landing',
        hideInMenu: false,
        exact: true,
      },
      {
        path: '/login',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Login />
          </Suspense>
        ),
      },
      {
        path: '/admin18378',
        exact: true,
        component: () => (
          <Suspense fallback={PageLoading}>
            <Login user_password_login={true} />
          </Suspense>
        ),
      },
    ],
  },
];

export default routes;
