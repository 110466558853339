import { print } from 'graphql';
import {
  LOGIN,
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_TOKEN,
  USERS_COUNT_BY_ROLE,
  USERS_COUNT,
  GET_PROFILE_PICTURE,
  GET_USER_CONTACT_DATA,
  GET_USER_COMMENTS,
  GET_USER_REPUTATION,
} from './User';
import { SETTINGS, SETTING_KEY } from './AppSetting';
import { LANGUAGES } from './Language';
import { ROLE, ROLES } from './Role';
import { PERMISSIONS } from './Permission';
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from './RolePermission';
import { OPERATIONS } from './Operation';
import { DEPARTMENT, DEPARTMENTS } from './Department';
import { LOCATIONS } from './Location';
import { PRODUCTS } from './Product';
import { PROVINCES } from './Province';
import { UNLOAD_TYPES } from './UnloadType';
import { DISTANCE } from './Distance';
import { GET_TRUCK_PICTURE, TRUCK, TRUCKS } from './Truck';
import { GET_TRAILER_PICTURE, TRAILER, TRAILERS } from './Trailer';
import {
  MAX_AND_MIN_DISTANCE_TRIP,
  TRIP,
  TRIPS,
  TRIPS_COUNT,
  TRIPS_FOR_CARRIER,
  TRIPS_FOR_CARRIER_WITH_FILTER,
  TRIP_FOR_CARRIER,
} from './Trips';
import {
  APPLICATION,
  APPLICATION_LIST,
  APPLICATION_FOR_CARRIER,
  AVERAGE_PRICE_APPLICATION,
  QUANTITY_OF_TRIPS_CONFIRMED,
} from './Application';
import { PUBLISHERS_BACKOFFICE, PUBLISHER_NEWS } from './Publisher';
import { NOTIFICATIONS } from './Notification';
import { CARRIERS_BACKOFFICE } from './Carrier';
import { LOGS } from './AuditLog';
import { TRUCK_TYPES } from './TruckType';

const Query = {
  authenticate: { name: 'authenticate', gql: AUTH },
  getAppSettingByKey: { name: 'getAppSettingByKey', gql: SETTING_KEY },
  getAppSettings: { name: 'getAppSettings', gql: SETTINGS },
  languages: { name: 'languages', gql: print(LANGUAGES) },
  login: { name: 'login', gql: LOGIN },
  logout: { name: 'logout', gql: LOGOUT },
  operations: { name: 'operations', gql: OPERATIONS },
  permissions: { name: 'permissions', gql: PERMISSIONS },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  role: { name: 'role', gql: ROLE },
  roles: { name: 'roles', gql: ROLES },
  rolePermission: { name: 'rolePermission', gql: ROLE_PERMISSION },
  rolePermissions: { name: 'rolePermissions', gql: ROLE_PERMISSIONS },
  users: { name: 'users', gql: USERS },
  userCount: { name: 'userCount', gql: USERS_COUNT },
  userCountByRole: { name: 'userCountByRole', gql: USERS_COUNT_BY_ROLE },
  verifyRecoveryPasswordToken: {
    name: 'verifyRecoveryPasswordToken',
    gql: VERIFY_RECOVERY_PASSWORD_TOKEN,
  },
  department: { name: 'department', gql: DEPARTMENT },
  departments: { name: 'departments', gql: DEPARTMENTS },
  location: { name: 'location', gql: LOCATIONS },
  locations: { name: 'locations', gql: LOCATIONS },
  products: { name: 'products', gql: PRODUCTS },
  provinces: { name: 'provinces', gql: PROVINCES },
  unload_types: { name: 'unload_types', gql: UNLOAD_TYPES },
  tripDistance: { name: 'tripDistance', gql: DISTANCE },
  getProfilePicture: { name: 'getProfilePicture', gql: GET_PROFILE_PICTURE },
  trucks: { name: 'trucks', gql: TRUCKS },
  truck: { name: 'truck', gql: TRUCK },
  getTruckPicture: { name: 'getTruckPicture', gql: GET_TRUCK_PICTURE },
  trailers: { name: 'trailers', gql: TRAILERS },
  trailer: { name: 'trailer', gql: TRAILER },
  getTrailerPicture: { name: 'getTrailerPicture', gql: GET_TRAILER_PICTURE },
  application: { name: 'application', gql: APPLICATION },
  trips: { name: 'trips', gql: TRIPS },
  tripsWithCountApplicationsForPublisher: {
    name: 'tripsWithCountApplicationsForPublisher',
    gql: TRIPS_COUNT,
  },
  tripsInCarrierWorkZone: {
    name: 'tripsInCarrierWorkZone',
    gql: TRIPS_FOR_CARRIER,
  },
  getUserContactData: {
    name: 'getUserContactData',
    gql: GET_USER_CONTACT_DATA,
  },
  getUserComments: {
    name: 'getUserComments',
    gql: GET_USER_COMMENTS,
  },
  getUserReputation: {
    name: 'getUserReputation',
    gql: GET_USER_REPUTATION,
  },
  newsPublisher: { name: 'newsPublisher', gql: PUBLISHER_NEWS },
  applicationsForPublisher: {
    name: 'applicationsForPublisher',
    gql: APPLICATION_LIST,
  },
  tripsForCarrier: {
    name: 'tripsForCarrier',
    gql: TRIPS_FOR_CARRIER_WITH_FILTER,
  },
  applicationsForCarrier: {
    name: 'applicationsForCarrier',
    gql: APPLICATION_FOR_CARRIER,
  },
  notifications: {
    name: 'notifications',
    gql: NOTIFICATIONS,
  },
  trip: {
    name: 'trip',
    gql: TRIP,
  },
  averagePriceApplication: {
    name: 'averagePriceApplication',
    gql: AVERAGE_PRICE_APPLICATION,
  },
  gridCarrier: {
    name: 'gridCarrier',
    gql: CARRIERS_BACKOFFICE,
  },
  gridPublisher: {
    name: 'gridPublisher',
    gql: PUBLISHERS_BACKOFFICE,
  },
  logs: {
    name: 'logs',
    gql: LOGS,
  },
  truckTypes: {
    name: 'truckTypes',
    gql: TRUCK_TYPES,
  },
  tripForCarrier: {
    name: 'tripForCarrier',
    gql: TRIP_FOR_CARRIER,
  },
  maxAndMinDistanceTrip: {
    name: 'maxAndMinDistanceTrip',
    gql: MAX_AND_MIN_DISTANCE_TRIP,
  },
  quantityOfTripsConfirmed: {
    name: 'quantityOfTripsConfirmed',
    gql: QUANTITY_OF_TRIPS_CONFIRMED,
  },
};

export { Query };
