export const OPERATIONS = `query operations(
  $filter:  FilterOperationInput,
  $orderBy:  OperationOrderInput,
  $skip: Int,
  $take: Int,
){
operations(
filter: $filter
orderBy: $orderBy
skip: $skip
take: $take
)
{
  __typename
  ... on Operation{
    id
    name
    start_date
    end_date
  }
  ... on ResultError{
      status_code
      message
  }
}
}`;
