import { PageLoading } from '@ant-design/pro-layout';
import { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import ExceptionManager from '../../components/ExceptionManager';
import './index.less';
import LogoRodar from './assets/RODAR LOGO PNG.png';

const PublicLayout = ({ route }: any) => {
  return (
    <div className="PublicLayout">
      {/* Aca se puede crear elementos para todas las paginas*/}
      {/* <div className="PublicLayoutTitle">Proyecto Base</div> */}
      {/* child routes won't render without this */}
      <div className="PublicLayoutContent">
        <img
          style={{ marginBottom: '50px', width: '200px' }}
          src={LogoRodar}
          alt="Rodar"
        />
        <Suspense fallback={<PageLoading />}>
          <ExceptionManager>{renderRoutes(route.routes)}</ExceptionManager>{' '}
        </Suspense>
      </div>
      {/* <div className="PublicLayoutFooter">Footer</div> */}
    </div>
  );
};

export default PublicLayout;
