import { Badge, Spin, Tooltip } from 'antd';
import { CheckSquareOutlined, LoadingOutlined } from '@ant-design/icons';
import { IApplicationsQuantity } from '../../../../services/Application';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import GraphqlService from '../../../../services/graphql/GraphqlService';
import useCurrentRoute from '../../../../shared/hooks/useCurrentRoute';

interface ICounterOfTripsConfirmedProps {
  onClick?: (quantityOfTripsConfirmed: number) => void;
  timeOfTooltipVisible?: number;
  title?: string;
  style?: CSSProperties;
  className?: string;
}

const DEFAULT_TIME_TOOLTIP_VISIBLE = 3000;
/**
 * @param onClick Función onClick que desee ejecutar cuando el usuario haga click en el icono
 * @param timeOfTooltipVisible Tiempo de visibilidad del tooltip en milisegundos. Por defecto 3000ms
 * @param title Titulo del tooltip. Por defecto "Cantidad de viajes confirmados"
 */
export const CounterOfTripsConfirmed = (
  props: ICounterOfTripsConfirmedProps,
) => {
  const { timeOfTooltipVisible, onClick, title, className, style } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(true);
  const [quantityOfTripsConfirmed, setQuantityOfTripsConfirmed] =
    useState<number>(0);
  const { leafRoute } = useCurrentRoute();

  const { customRequest, Query } = GraphqlService();

  const getQuantityOfTripsConfirmed = useCallback(async () => {
    setLoading(() => true);
    try {
      const data: IApplicationsQuantity = await customRequest({
        query: Query.quantityOfTripsConfirmed,
        variables: {},
      });
      setQuantityOfTripsConfirmed(data.quantity);
    } catch (err: any) {
      console.error(err);
    }
    setLoading(() => false);
  }, []);

  useEffect(() => {
    getQuantityOfTripsConfirmed();
  }, [leafRoute]);
  useEffect(() => {
    setTimeout(() => {
      setTooltipVisible(false);
    }, timeOfTooltipVisible || DEFAULT_TIME_TOOLTIP_VISIBLE);
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: '20px', color: 'white' }} spin />
  );

  return (
    <>
      {loading ? (
        <>
          <Spin indicator={antIcon} />
        </>
      ) : (
        <>
          <Badge size="small" count={quantityOfTripsConfirmed}>
            <Tooltip
              title={title || 'Cantidad de viajes confirmados'}
              defaultVisible={tooltipVisible}
              visible={tooltipVisible}
            >
              <CheckSquareOutlined
                className={className}
                style={{ ...style }}
                onClick={() => {
                  onClick && onClick(quantityOfTripsConfirmed);
                }}
              />
            </Tooltip>
          </Badge>
        </>
      )}
    </>
  );
};
