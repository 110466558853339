import { NavContext } from '@ionic/react';
import { IPublisher } from './Publisher';
import { ICarrier } from './Carrier';
import { IProfile } from './profile';
import Service from './graphql/GraphqlService';
import { ContextApp } from '../shared/ContextApp';
import { useContext } from 'react';
import { IRole } from './role';
import { IUserRole } from './userRole';
import { logoutFirebase } from '../shared/firebase-config';
import { EnumsValues } from '../shared';
import { Mutation } from './graphql/mutation';

export const UserService = () => {
  const { customRequest, Query } = Service();
  const { navigate } = useContext(NavContext);
  const {
    setUser,
    setRoles,
    setFunctions,
    setAuth,
    setSessionExpired,
    user,
    setUserDataFirebase,
    setRoleSelected,
    setFormUserActive,
    setPictureProfileUrl,
  } = useContext(ContextApp);

  const authenticate = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      customRequest({
        query: Query.authenticate,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(null);
        });
    });
  };

  const removeFCMToken = async () => {
    try {
      const localUserFCMId = localStorage.getItem(
        EnumsValues.LocalStorageKeys.UserFirebaseMessagingId,
      );
      if (localUserFCMId && !Number.isNaN(localUserFCMId)) {
        const userFcmId = Number(localUserFCMId);
        await customRequest({
          mutation: Mutation.deleteRegisterDevice,
          variables: {
            id: userFcmId,
          },
        });
      }
    } catch (err) {
      console.error('Could not clean FCM token', err);
    }
  };

  const logout = async () => {
    return new Promise((resolve, reject) => {
      // TODO: falta implementar lista negra en servicio
      // customRequest({
      //   query: Query.logout,
      // })
      return new Promise((resolve, reject) => {
        resolve({});
      })
        .then(async (res: any) => {
          await removeFCMToken();
          localStorage.removeItem('token');
          localStorage.removeItem('role_selected');
          localStorage.removeItem('segmentSelected');
          if (user?.uid) {
            logoutFirebase();
          }
          setRoles(() => []);
          setFunctions(() => []);
          setAuth(false);
          setUserDataFirebase(() => null);
          setFormUserActive(true);
          setUser(() => null);
          setPictureProfileUrl(null);
          navigate('/login');
          setRoleSelected(undefined);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const expireSession = async () => {
    return new Promise((resolve, reject) => {
      return new Promise((resolve, reject) => {
        resolve({});
      })
        .then((res: any) => {
          localStorage.removeItem('token');
          setRoles(() => []);
          setFunctions(() => []);
          setAuth(false);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    authenticate,
    logout,
    expireSession,
  };
};

export interface IUser {
  id: number;
  username: string;
  registered_profile?: boolean;
  password?: string;
  email?: string;
  timezone: string;
  business_name?: string;
  contact_phone?: string;
  cuit_cuil?: string;
  active: boolean;
  phone: string;
  user_role?: IUserRole[];
  roles: IRole[];
  is_system_user: boolean;
  uid?: string;
  carrier?: ICarrier;
  publisher?: IPublisher;
  profile_picture_id?: number;
}

export interface IUserCountByRole {
  role: IRole;
  count: number;
}

export interface IUserCount {
  count: number;
}

export interface IUserFirebase {
  uid: string;
  token: string;
  role_id?: number;
  phoneNumber?: string;
  email?: string;
  displayName?: string;
  photoURL?: string;
}

export interface IUserReputation {
  experience: {
    name: string;
    id: number;
  };
  count_by_experience: number;
}

export interface IUserContactData {
  id: number;
  active: boolean;
  email: string;
  business_name: string;
  contact_phone: string;
  cuit_cuil: string;
  profile_picture_id: number;
}

export interface IUserComments {
  experience: IExperience;
  comments_by_experience: IcommentsByExperience[];
}
export interface IExperience {
  id: number;
  name: string;
}
export interface IcommentsByExperience {
  date: string;
  comments: string;
}
