import { LOGIN, LOGIN_FIREBASE } from '../query/User';
import {
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  UPDATE_USER,
  CREATE_USER,
  UPSERT_USER_ROLE,
  UPSERT_USER_FIREBASE,
  UPSERT_USER_PROFILE,
  CREATE_PROFILE_PICTURE,
  UPDATE_PROFILE_PICTURE,
} from './User';
import { CREATE_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from './Company';
import { UPDATE_SETTING } from './AppSetting';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from './Role';
import { UPSERT_ROLE_PERMISSION } from './RolePermission';
import {
  CREATE_OPERATION,
  DELETE_OPERATION,
  UPDATE_OPERATION,
} from './Operation';
import { REFRESH_TOKEN } from './RefreshToken';
import { UPDATE_PERMISSION } from './Permission';
import {
  ACTIVE_TRIP,
  CREATE_TRIP,
  DELETE_TRIP,
  DISABLE_TRIP,
  UPDATE_TRIP,
} from './Trip';
import { UPSERT_WORK_ZONE } from './WorkZone';
import {
  CARRIER_ACCEPT_TERMS_AND_CONDITIONS,
  VALIDATE_CARRIER,
} from './Carrier';
import {
  PUBLISHER_ACCEPT_TERMS_AND_CONDITIONS,
  VALIDATE_PUBLISHER,
} from './Publisher';
import {
  CREATE_TRUCK,
  CREATE_TRUCK_PICTURE,
  DELETE_TRUCK,
  UPDATE_TRUCK,
  UPDATE_TRUCK_PICTURE,
} from './Truck';
import {
  CREATE_TRAILER,
  UPDATE_TRAILER,
  DELETE_TRAILER,
  CREATE_TRAILER_PICTURE,
  UPDATE_TRAILER_PICTURE,
} from './Trailer';
import {
  CREATE_APPLICATION,
  DELETE_APPLICATION,
  UPDATE_APPLICATION,
  ACCEPT_REJECT_APPLICATION,
  COMPLETED_APPLICATION,
} from './Application';
import { DELETE_NOTIFICATION } from './Notification';
import { DELETE_REGISTER_DEVICE, REGISTER_DEVICE } from './FirebaseMessaging';

const Mutation = {
  createCompany: { name: 'createCompany', gql: CREATE_COMPANY },
  createOperation: { name: 'createOperation', gql: CREATE_OPERATION },
  createRole: { name: 'createRole', gql: CREATE_ROLE },
  createUser: { name: 'createUser', gql: CREATE_USER },
  deleteCompany: { name: 'deleteCompany', gql: DELETE_COMPANY },
  deleteOperation: { name: 'deleteOperation', gql: DELETE_OPERATION },
  deleteRole: { name: 'deleteRole', gql: DELETE_ROLE },
  deleteUser: { name: 'deleteUser', gql: DELETE_USER },
  login: { name: 'login', gql: LOGIN },
  loginFirebase: { name: 'loginFirebase', gql: LOGIN_FIREBASE },
  resetPassword: { name: 'resetPassword', gql: RESET_PASSWORD },
  setPassword: { name: 'setPassword', gql: SET_PASSWORD },
  updateAppSetting: { name: 'updateAppSetting', gql: UPDATE_SETTING },
  updateCompany: { name: 'updateCompany', gql: UPDATE_COMPANY },
  updateOperation: { name: 'updateOperation', gql: UPDATE_OPERATION },
  updatePermission: { name: 'updatePermission', gql: UPDATE_PERMISSION },
  updateRole: { name: 'updateRole', gql: UPDATE_ROLE },
  updateUser: { name: 'updateUser', gql: UPDATE_USER },
  upsertUserRole: { name: 'upsertUserRole', gql: UPSERT_USER_ROLE },
  upsertRolePermission: {
    name: 'upsertRolePermission',
    gql: UPSERT_ROLE_PERMISSION,
  },
  refreshToken: { name: 'refreshToken', gql: REFRESH_TOKEN },
  upsertUserFirebase: { name: 'upsertUserFirebase', gql: UPSERT_USER_FIREBASE },
  createTrip: { name: 'createTrip', gql: CREATE_TRIP },
  updateTrip: { name: 'updateTrip', gql: UPDATE_TRIP },
  upsertProfileData: { name: 'upsertProfileData', gql: UPSERT_USER_PROFILE },
  upsertWorkZone: { name: 'upsertWorkZone', gql: UPSERT_WORK_ZONE },
  validateCarrier: { name: 'validateCarrier', gql: VALIDATE_CARRIER },
  validatePublisher: { name: 'validatePublisher', gql: VALIDATE_PUBLISHER },
  createTruck: { name: 'createTruck', gql: CREATE_TRUCK },
  updateTruck: { name: 'updateTruck', gql: UPDATE_TRUCK },
  deleteTruck: { name: 'deleteTruck', gql: DELETE_TRUCK },
  createTruckPicture: { name: 'createTruckPicture', gql: CREATE_TRUCK_PICTURE },
  createProfilePicture: {
    name: 'createProfilePicture',
    gql: CREATE_PROFILE_PICTURE,
  },
  updateProfilePicture: {
    name: 'updateProfilePicture',
    gql: UPDATE_PROFILE_PICTURE,
  },
  updateTruckPicture: { name: 'updateTruckPicture', gql: UPDATE_TRUCK_PICTURE },
  createTrailer: { name: 'createTrailer', gql: CREATE_TRAILER },
  updateTrailer: { name: 'updateTrailer', gql: UPDATE_TRAILER },
  deleteTrailer: { name: 'deleteTrailer', gql: DELETE_TRAILER },
  createTrailerPicture: {
    name: 'createTrailerPicture',
    gql: CREATE_TRAILER_PICTURE,
  },
  updateTrailerPicture: {
    name: 'updateTrailerPicture',
    gql: UPDATE_TRAILER_PICTURE,
  },
  createApplication: {
    name: 'createApplication',
    gql: CREATE_APPLICATION,
  },
  updateApplication: {
    name: 'updateApplication',
    gql: UPDATE_APPLICATION,
  },
  deleteApplication: {
    name: 'deleteApplication',
    gql: DELETE_APPLICATION,
  },
  acceptRejectApplication: {
    name: 'acceptRejectApplication',
    gql: ACCEPT_REJECT_APPLICATION,
  },
  deleteNotification: {
    name: 'deleteNotification',
    gql: DELETE_NOTIFICATION,
  },
  registerDevice: {
    name: 'registerDevice',
    gql: REGISTER_DEVICE,
  },
  deleteRegisterDevice: {
    name: 'deleteRegisterDevice',
    gql: DELETE_REGISTER_DEVICE,
  },
  disabledTrip: {
    name: 'disabledTrip',
    gql: DISABLE_TRIP,
  },
  activeTrip: {
    name: 'activeTrip',
    gql: ACTIVE_TRIP,
  },
  completedApplication: {
    name: 'completedApplication',
    gql: COMPLETED_APPLICATION,
  },
  deleteTrip: {
    name: 'deleteTrip',
    gql: DELETE_TRIP,
  },
  carrierAcceptTermsAndConditions: {
    name: 'carrierAcceptTermsAndConditions',
    gql: CARRIER_ACCEPT_TERMS_AND_CONDITIONS,
  },
  publisherAcceptTermsAndConditions: {
    name: 'publisherAcceptTermsAndConditions',
    gql: PUBLISHER_ACCEPT_TERMS_AND_CONDITIONS,
  },
};

export { Mutation };
