import { isPlatform } from '@ionic/core';
import { IonTabBar, IonTabButton } from '@ionic/react';
import { Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { useContext, useEffect, useState } from 'react';
import { IRouteConfig } from '../../../routes';
import { ContextApp, EnumsValues } from '../../../shared';
import { HeaderComponent } from '../HeaderComponent';
import { MainMenu } from '../MainMenu';
import Drive from '../assets/drive.svg';
import DriveColor from '../assets/drive_color.svg';
import Add from '../assets/add.svg';
import AddColor from '../assets/add_color.svg';
import Home from '../assets/Home.svg';
import HomeColor from '../assets/Home_color.svg';
import './style.less';
import { useHistory, useParams } from 'react-router';

interface ILayoutComponent {
  route: any;
  children: React.ReactNode;
  tabBar?: boolean;
}

const ROUTES_WITH_GRAY_BACKGROUND = [
  '/app/home',
  '/app/trips',
  '/app/tripHistory',
  '/app/applications',
  '/app/applicationsCompleted',
];

export const LayoutComponent = (props: ILayoutComponent) => {
  const { route, children, tabBar } = props;
  const {
    mainMenuCollapsed,
    setMainMenuCollapsed,
    menuBreakpointCollapsed,
    setMenuBreakpointCollapsed,
    roleSelected,
  } = useContext(ContextApp);
  const { location } = useHistory();
  const params: any = useParams();
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const [tabSelected, setTabSelected] = useState<string>('');
  const getBreakpointExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed) {
      return {
        marginLeft: 0,
      };
    }
    return {
      marginLeft: 200,
    };
  };

  useEffect(() => {
    if (!isPlatform('desktop')) {
      if (location.pathname === '/app/home') {
        setTabSelected('home');
      } else if (location.pathname === '/app/createTrip') {
        setTabSelected('create-trip');
      } else if (location.pathname === '/app/trips') {
        setTabSelected('my-trips');
      } else {
        setTabSelected('');
      }
    }
  }, [location]);

  const getBackdropExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed && !mainMenuCollapsed) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'none',
    };
  };
  useEffect(() => {
    let parsed_route;
    let quantity_slash = window.location.pathname.match(
      new RegExp('/', 'g') || [],
    )?.length;
    if (quantity_slash && quantity_slash > 2) {
      parsed_route = window.location.pathname.slice(
        0,
        window.location.pathname.indexOf(
          `/${window.location.pathname.split('/')[3]}`,
        ),
      );
      setCurrentRoute(parsed_route);
    } else {
      setCurrentRoute(window.location.pathname);
    }
  }, [params]);

  return (
    <>
      <Layout className="RegisteredLayout">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          collapsible
          collapsed={mainMenuCollapsed}
          trigger={null}
          onBreakpoint={(broken) => {
            setMainMenuCollapsed(() => broken);
            setMenuBreakpointCollapsed(() => broken);
          }}
          theme="light"
        >
          <MainMenu routes={route.routes as IRouteConfig[]} />
        </Sider>
        <Layout
          style={
            mainMenuCollapsed
              ? {
                  marginLeft: 0,
                }
              : getBreakpointExpandedStyles()
          }
        >
          <div
            className="backdrop-content"
            onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
            style={getBackdropExpandedStyles()}
          ></div>
          <HeaderComponent />
          <Content
            id="scrollableDiv"
            className="site-layout"
            style={{
              backgroundColor: ROUTES_WITH_GRAY_BACKGROUND.includes(
                currentRoute,
              )
                ? undefined
                : 'white',
              paddingTop: 0,
            }}
          >
            {children ? children : null}
          </Content>
          {tabBar ? (
            <>
              {isPlatform('mobileweb') ? (
                <>
                  <IonTabBar
                    slot="bottom"
                    className={'tab-bar-bottom'}
                    onIonTabsDidChange={(e) => setTabSelected(e.detail.tab)}
                  >
                    <IonTabButton tab="home" href="/app/home">
                      <img
                        src={tabSelected === 'home' ? HomeColor : Home}
                        alt="Home icon"
                        width="auto"
                        height="auto"
                      />
                    </IonTabButton>
                    {roleSelected === EnumsValues.UserRoleSystem.Publisher && (
                      <IonTabButton tab="create-trip" href="/app/createTrip">
                        <img
                          src={tabSelected === 'create-trip' ? AddColor : Add}
                          alt="Add icon"
                          height="auto"
                          width="auto"
                        />
                      </IonTabButton>
                    )}
                    <IonTabButton tab="my-trips" href="/app/trips">
                      <img
                        src={tabSelected === 'my-trips' ? DriveColor : Drive}
                        alt="Drive icon"
                        height="auto"
                        width="auto"
                      />
                    </IonTabButton>
                  </IonTabBar>
                </>
              ) : null}
            </>
          ) : null}
        </Layout>
      </Layout>
    </>
  );
};
