export interface ISegmentSelected {
  myTripsLastSegmentSelected?: string;
  historyTripsCarrierLastSegmentSelected?: string;
  historyTripsPublisherLastSegmentSelected?: string;
}

const memorizeSegmentSelected = (
  value: string,
  from:
    | 'myTripsLastSegmentSelected'
    | 'historyTripsCarrierLastSegmentSelected'
    | 'historyTripsPublisherLastSegmentSelected',
) => {
  const segmentSelectedStorage = localStorage.getItem('segmentSelected');
  if (segmentSelectedStorage) {
    let segmentSelected: ISegmentSelected = {
      ...JSON.parse(segmentSelectedStorage),
    };
    segmentSelected[from] = value;
    localStorage.setItem('segmentSelected', JSON.stringify(segmentSelected));
  } else {
    let segmentSelected: ISegmentSelected = {};
    segmentSelected[from] = value;
    localStorage.setItem('segmentSelected', JSON.stringify(segmentSelected));
  }
};
export default memorizeSegmentSelected;
