export const TRIP = `
query trip($id: Int!) {
  trip(id: $id) {
    __typename
    ... on Trip {
      id
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
            id
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
            id
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      publisher {
        user {
          business_name
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const TRIPS = `
query trips(
  $filter: FilterTripInput
  $orderBy: TripOrderInput
  $skip: Int
  $take: Int
) {
  trips(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Trip {
      id
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      publisher {
        user {
          business_name
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TRIPS_COUNT = `
query tripsWithCountApplicationsForPublisher(
  $filter: FilterTripForPublisherInput
  $orderBy: TripOrderInput
  $skip: Int
  $take: Int
) {
  tripsWithCountApplicationsForPublisher(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on TripWithCountApplications {
      uts
      id
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      count_applications_by_status {
        name
        count_by_status
        id
      }
      trip_status {
        id
        name
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TRIPS_FOR_CARRIER = `
query tripsInCarrierWorkZone($orderBy: TripOrderInput, $skip: Int, $take: Int) {
  tripsInCarrierWorkZone(orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on TripWithReputation {
      id
      publisher_id
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      user_reputation {
        experience {
          name
        }
        count_by_experience
      }
    }

    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TRIPS_FOR_CARRIER_WITH_FILTER = `
query tripsForCarrier($filterTripsForCarrier: FilterTripsForCarrierInput,
  $filter:FilterTripInput,$orderBy: TripOrderInput,$skip: Int,$take:Int){
  tripsForCarrier(
    filterTripsForCarrier: $filterTripsForCarrier,
    filter:$filter,
    orderBy:$orderBy,
    skip: $skip,
    take: $take,
    
  ){
    __typename
    ... on  TripForCarrierList{
      id
      publisher_id 
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      publisher_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      user_reputation {
        experience {
          name
          id
        }
        count_by_experience
      }
      active_applications
    }

    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const TRIP_FOR_CARRIER = `
query tripForCarrier($id: Int!){
  tripForCarrier(
    id: $id
  ){
    __typename
    ... on  TripForCarrier{
      id
      publisher_id 
      quantity_tn
      load_date
      unload_date
      distance_km
      product_id
      publisher_id
      product {
        name
        id
      }
      origin {
        department {
          province {
            name
          }
        }
        id
        name
      }
      destination {
        department {
          province {
            name
          }
        }
        id
        name
      }
      unload_type {
        id
        name
      }
      user_reputation {
        experience {
          name
          id
        }
        count_by_experience
      }
      active_applications
    }

    ...on ResultError{
      status_code
      message
    }
  }
}`;

export const MAX_AND_MIN_DISTANCE_TRIP = `
query maxAndMinDistanceTrip{
  maxAndMinDistanceTrip{
    __typename
    ...on TripDistances{
      min_distance
      max_distance
      min_position
      max_position
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
