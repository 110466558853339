enum UserRoleSystem {
  Admin = 1,
  Carrier = 2,
  Publisher = 3,
}

enum Functions {
  AppSettingRead = 'APP_SETTING_R',
  AppSettingUpdate = 'APP_SETTING_U',
  CompanyCreate = 'COMPANY_C',
  CompanyDelete = 'COMPANY_D',
  CompanyGet = 'COMPANY_G',
  CompanyList = 'COMPANY_L',
  CompanyUpadate = 'COMPANY_U',
  OperationCreate = 'OPERATION_C',
  OperationDelete = 'OPERATION_D',
  OperationGet = 'OPERATION_G',
  OperationList = 'OPERATION_L',
  OperationUpdate = 'OPERATION_U',
  OperationVehicleGet = 'OPERATION_VEHICLE_G',
  OperationVehicleList = 'OPERATION_VEHICLE_L',
  OperationVehicleUpdate = 'OPERATION_VEHICLE_U',
  PermissionGet = 'PERMISSION_G',
  PermissionList = 'PERMISSION_L',
  PermissionUpdate = 'PERMISSION_U',
  RoleCreate = 'ROLE_C',
  RoleDelete = 'ROLE_D',
  RoleGet = 'ROLE_G',
  RoleList = 'ROLE_L',
  RoleUpdate = 'ROLE_U',
  RolePermissionCreate = 'ROLE_PERMISSION_C',
  RolePermissionGet = 'ROLE_PERMISSION_G',
  RolePermissionDelete = 'ROLE_PERMISSION_D',
  RolePermissionList = 'ROLE_PERMISSION_L',
  RolePermissionUpdate = 'ROLE_PERMISSION_U',
  TraceCreate = 'TRAC_C',
  TraceGet = 'TRAC_G',
  TraceList = 'TRAC_L',
  UserCreate = 'USER_C',
  UserCompanyGet = 'USER_COMPANY_G',
  UserCompanyList = 'USER_COMPANY_L',
  UserCompanyUpdate = 'USER_COMPANY_U',
  UserDelete = 'USER_D',
  UserGet = 'USER_G',
  UserList = 'USER_L',
  UserRoleGet = 'USER_ROLE_G',
  UserRoleList = 'USER_ROLE_L',
  UserRoleUpdate = 'USER_ROLE_U',
  UserUpdate = 'USER_U',
  VehicleCreate = 'VEHICLE_C',
  VehicleCompanyGet = 'VEHICLE_COMPANY_G',
  VehicleCompanyList = 'VEHICLE_COMPANY_L',
  VehicleCompanyUpdate = 'VEHICLE_COMPANY_U',
  VehicleDelete = 'VEHICLE_D',
  VehicleGet = 'VEHICLE_G',
  VehicleList = 'VEHICLE_L',
  VehicleTypeCreate = 'VEHICLE_TYPE_C',
  VehicleTypeDelete = 'VEHICLE_TYPE_D',
  VehicleTypeGet = 'VEHICLE_TYPE_G',
  VehicleTypeList = 'VEHICLE_TYPE_L',
  VehicleTypeUpdate = 'VEHICLE_TYPE_U',
  VehicleUpdate = 'VEHICLE_U',
  UserProfileUpdate = 'USER_PROFILE_U',
  DepartmentRead = 'DEPARTMENT_R',
  DepartmentCreate = 'DEPARTMENT_C',
  DepartmentUpdate = 'DEPARTMENT_U',
  DepartmentDelete = 'DEPARTMENT_D',
  LocationRead = 'LOCATION_R',
  LocationCreate = 'LOCATION_C',
  LocationUpdate = 'LOCATION_U',
  LocationDelete = 'LOCATION_D',

  WorkZoneCreate = 'WORK_ZONE_C',
  WorkZoneRead = 'WORK_ZONE_R',
  WorkZoneUpdate = 'WORK_ZONE_U',
  WorkZoneDelete = 'WORK_ZONE_D',

  TruckCreate = 'TRUCK_C',
  TruckRead = 'TRUCK_R',
  TruckUpdate = 'TRUCK_U',
  DeleteTruck = 'TRUCK_D',

  ProvinceCreate = 'PROVINCE_C',
  ProvinceRead = 'PROVINCE_R',
  ProvinceUpdate = 'PROVINCE_U',
  ProvinceDelete = 'PROVINCE_D',

  ProfilePictureCreate = 'PROFILE_PICTURE_C',
  ProfilePictureRead = 'PROFILE_PICTURE_R',
  ProfilePictureUpdate = 'PROFILE_PICTURE_U',
  ProfilePictureDelete = 'PROFILE_PICTURE_D',

  TrailerCreate = 'TRAILER_C',
  TrailerRead = 'TRAILER_R',
  TrailerUpdate = 'TRAILER_U',
  TrailerDelete = 'TRAILER_D',

  TruckPictureCreate = 'TRUCK_PICTURE_C',
  TruckPictureRead = 'TRUCK_PICTURE_R',
  TruckPictureUpdate = 'TRUCK_PICTURE_U',
  TruckPictureDelete = 'TRUCK_PICTURE_D',

  TrailerPictureCreate = 'TRAILER_PICTURE_C',
  TrailerPictureRead = 'TRAILER_PICTURE_R',
  TrailerPictureUpdate = 'TRAILER_PICTURE_U',
  TrailerPictureDelete = 'TRAILER_PICTURE_D',

  UnloadTypeRead = 'UNLOAD_TYPE_R',

  TripCreate = 'TRIP_C',
  TripRead = 'TRIP_R',
  TripUpdate = 'TRIP_U',
  TripDelete = 'TRIP_D',

  BackOfficeLayout = 'BACK_OFFICE_LAYOUT',

  CarrierRead = 'CARRIER_R',
  PublisherRead = 'PUBLISHER_R',

  TruckTypeCreate = 'TRUCK_TYPE_C',
  TruckTypeRead = 'TRUCK_TYPE_R',
  TruckTypeUpdate = 'TRUCK_TYPE_U',
  TruckTypeDelete = 'TRUCK_TYPE_D',
  TripSharedRead = 'TRIP_SHARED_R',

  NotificationRead = 'NOTIFICATION_R',

  FilterOfTripsByMyWorkZone = 'FILTER_OF_TRIPS_BY_MY_WORK_ZONE',
  QuantityOfTripsConfirmedRead = 'QUANTITY_OF_TRIPS_CONFIRMED_R',
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  userPhone = 50,
  username = 100,
  userPassword = 200,
  userTimezone = 100,
  userBusinessName = 50,
  userContactPhone = 50,
  userEmail = 100,
  userUid = 100,
  userCuitCuil = 15,
  truckLicensePlate = 100,
  truckMark = 100,
  truckModel = 100,
  trailerLicensePlate = 50,
  trailerMark = 100,
  trailerModel = 100,
  year = 4,
  roleName = 200,
  ton = 6,
  distance = 7,
  price = 15,
}

enum LocalStorageKeys {
  RoleSelected = 'role_selected',
  TranslationsVersions = 'translation_versions',
  Translations = 'translations',
  UserFirebaseMessagingId = 'user_fcm_id',
  BackUrlToTripApplication = 'backUrlToTripApplication',
  BackUrlAfterLogin = 'backUrlAfterLogin',
  BackUrlToUpdateTrip = 'backUrlToUpdateTrip',
}

export enum SettingNames {
  RecoveryPasswordFrom = 'RECOVERY_PASSWORD_FROM',
  RecoveryPasswordSubject = 'RECOVERY_PASSWORD_SUBJECT',
  NodeMailerHost = 'NODEMAILER_HOST',
  NodeMailerPort = 'NODEMAILER_PORT',
  NodeMailerSecure = 'NODEMAILER_SECURE',
  NodeMailerUser = 'NODEMAILER_USER',
  NodeMailerPassword = 'NODEMAILER_PASSWORD',
  NodeMailerAccountType = 'NODEMAILER_ACCOUNT_TYPE',
  UrlSite = 'URL_SITE',
  PasswordRegex = 'PASSWORD_REGEX',
  ProfileRequired = 'PROFILE_REQUIRED',
  SecurityTokenExpiresin = 'SECURITY_TOKEN_EXPIRESIN',
  SecurityRefreshTokenExpiresin = 'SECURITY_REFRESH_TOKEN_EXPIRESIN',
  FirstLogin = 'FIRST_LOGIN',
  NumberOfDepartmentsInWorkZone = 'NUMBER_OF_DEPARTMENTS_IN_WORK_ZONE',
  LimitMaxSizeFile = 'LIMIT_MAX_SIZE_FILE',
  ContactEmail = 'CONTACT_EMAIL',
}

export enum TripSteps {
  TripData = 0,
  TripDate = 1,
}

export enum ApplicationStatusId {
  Pendiente = 1,
  Aceptada = 2,
  Rechazada = 3,
  FinalizadaPorTransportista = 4,
  FinalizadaPorDadorDeCarga = 5,
  Finalizada = 6,
}

export enum ConstNumbers {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
}

export enum ApplicationStatus {
  Pending = 'Pendiente',
  Accepted = 'Aceptada',
  Rejected = 'Rechazada',
  Completed = 'Finalizada',
  CompletedByPublisher = 'Finalizada por Dador de Carga',
}

export enum UserReputation {
  Good = 'Buena',
  Bad = 'Mala',
}

export enum UserReputationId {
  Good = 1,
  Bad = 2,
}

export enum NotificationType {
  NewTripApplication = 1,
  ApplicationAccepted = 2,
  ApplicationRejected = 3,
  ApplicationCompletedByCarrier = 4,
  ApplicationCompletedByPublisher = 5,
  ApplicationExpired = 6,
  TripExpired = 7,
  TripCompleted = 8,
}

export enum TripStatusNumber {
  Active = 1,
  Inactive = 2,
}

export enum ApplicationStatusNumber {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
  CompletedByCarrier = 4,
  CompletedByPublisher = 5,
  Expired = 6,
  Dued = 7,
}

export enum ApplicationStatusString {
  Pending = 'pending',
  Confirmed = 'confirmed',
  Rejected = 'rejected',
}

export enum BackendError {
  recordNotFoundCode = 2,
}

export enum MaxValues {
  distance = 9999999,
  ton = 9999999,
  price = 999999999999999,
}

export enum UnloadTypesIds {
  SinDefinir = 1,
  Trasera = 2,
  Bilateral = 3,
}

export enum SystemLimits {
  //Se define limite de tamaño de archivos en bytes
  /*
    Recordar que este enum debe corresponder con el de backend.
  */
  MaxSizeOfFiles = 20000000, //20 MB,
}

export enum ProductsIds {
  Petreos = 1,
  Suelos = 2,
  Arenas = 3,
  Otros = 4,
}

export enum IonSegmentsValues {
  CarrierTripSegmentConfirmed = 'confirmed',
  CarrierTripSegmentPending = 'pending',
  ApplicationListSegmentConfirmed = 'confirmed',
  ApplicationListSegmentRejected = 'rejected',
  ApplicationListSegmentPending = 'pending',
  CarrierTripHistorySegmentFinalized = 'finalized',
  CarrierTripHistorySegmentRejected = 'rejected',
  PublisherTripHistorySegmentClosed = 'closed',
  PublisherTripHistorySegmentInactive = 'inactive',
}

export class EnumsValues {
  static readonly BackendError = BackendError;

  readonly BackendError = EnumsValues.BackendError;

  static readonly ApplicationStatusString = ApplicationStatusString;

  readonly ApplicationStatusString = EnumsValues.ApplicationStatusString;

  static readonly ApplicationStatusNumber = ApplicationStatusNumber;

  readonly ApplicationStatusNumber = EnumsValues.ApplicationStatusNumber;

  static readonly TripStatusNumber = TripStatusNumber;

  readonly TripStatusNumber = EnumsValues.TripStatusNumber;

  static readonly UserReputationId = UserReputationId;

  readonly UserReputationId = EnumsValues.UserReputationId;

  static readonly UserReputation = UserReputation;

  readonly UserReputation = EnumsValues.UserReputation;

  static readonly ApplicationStatusId = ApplicationStatusId;

  readonly ApplicationStatusId = EnumsValues.ApplicationStatusId;

  static readonly ApplicationStatus = ApplicationStatus;

  readonly ApplicationStatus = EnumsValues.ApplicationStatus;

  static readonly ConstNumbers = ConstNumbers;

  readonly ConstNumbers = EnumsValues.ConstNumbers;

  static readonly TripSteps = TripSteps;

  readonly TripSteps = EnumsValues.TripSteps;

  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly UserRoleSystem = UserRoleSystem;

  readonly UserRoleSystem = EnumsValues.UserRoleSystem;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly SettingNames = SettingNames;

  readonly SettingNames = EnumsValues.SettingNames;

  static readonly MaxValues = MaxValues;

  readonly MaxValues = EnumsValues.MaxValues;

  static readonly UnloadTypesIds = UnloadTypesIds;

  readonly UnloadTypesIds = EnumsValues.UnloadTypesIds;

  static readonly SystemLimits = SystemLimits;

  readonly SystemLimits = EnumsValues.SystemLimits;

  static readonly ProductsIds = ProductsIds;

  readonly ProductsIds = EnumsValues.ProductsIds;

  static readonly IonSegmentsValues = IonSegmentsValues;

  readonly IonSegmentsValues = EnumsValues.IonSegmentsValues;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
