export const LOGS = `query logs(
  $filter: FilterLogInput
  $searchText: String
  $orderBy: LogOrderInput
  $skip: Int
  $take: Int
) {
  logs(
    filter: $filter
    searchText: $searchText
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on Log {
      id
      action{
        name
      }
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
