export const TRAILER = `
query trailer($id: Int!){
  trailer(
    id: $id
  ){
    __typename
    ...on Trailer{
      id
      license_plate
      mark
      model
      year
      max_load_tn
      unload_type_id     
      trailer_picture{
        id          
      }
    }
    ...on ResultError{
      status_code
      message
    }  
  }
}
`;
export const TRAILERS = `
query trailers(
    $searchText:  String,
    $filter:  FilterTrailerInput,
    $ordedrBy:  TrailerOrderInput,
    $skip: Int,
    $take:Int
  ){
    trailers(
      searchText:$searchText,
      filter:$filter,
      orderBy:$ordedrBy,
      skip:$skip,
      take:$take
    ){
      __typename
      ...on Trailer{
        id
        license_plate
        mark
        model
        year
        max_load_tn
        unload_type_id     
        trailer_picture{
          id          
        }
      }
      ...on ResultError{
        status_code
        message
      }    
    }
  }`;

export const GET_TRAILER_PICTURE = `query getTrailerPicture($id: Int!){
    getTrailerPicture(
      id: $id
    ){
      __typename
      ...on TrailerPicture{
        id
        filename
        mimetype
        encoding
        file
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
  `;
