export const LOCATION = `
query location($id: Int!){
    location(
      id: $id
    ){
        __typename
      ...on Location{
        id
        name
        id_datagobar
        department_id
        lat
        lon
        active      
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
`;

export const LOCATIONS = `
query locations(
  $searchText:  String
  $filter: FilterLocationInput
  $orderBy: LocationOrderInput
  $skip: Int
  $take: Int
){
  locations(
    searchText:$searchText,
    filter:$filter,
    orderBy:$orderBy,
    skip:$skip,
    take:$take,      
  ){
      __typename
    ...on Location{
      id
      name
      id_datagobar
      department_id
      lat
      lon
      active      
      department{
        province_id
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
