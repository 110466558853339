export const TRUCK = `
query truck($id: Int!){
  truck(
    id: $id
  ){
    __typename
    ...on Truck{
      id
      license_plate{
        id
        value
        configuration_license_plate{
          id
          label
          position
        }
      }
      mark
      model
      year
      max_load_tn
      unload_type_id     
      truck_picture{
        id          
      }
      truck_type{
        id
        name
        ref_icon
        configuration_license_plate{
          id
          label
          position
        }
      }
     has_applications
    }
    ...on ResultError{
      status_code
      message
    }  
  }
}
`;
export const TRUCKS = `
query trucks(
  $searchText:  String,
  $filter:  FilterTruckInput,
  $ordedrBy:  TruckOrderInput,
  $skip: Int,
  $take:Int
){
  trucks(
    searchText:$searchText,
    filter:$filter,
    orderBy:$ordedrBy,
    skip:$skip,
    take:$take
  ){
    __typename
    ...on Truck{
      id
      license_plate{
        id
        value
        configuration_license_plate{
          id
          label
          position
        }
      }
      mark
      model
      year
      max_load_tn
      unload_type_id     
      truck_picture{
        id        
        filename
        mimetype
        encoding
        file
      }
      truck_type{
        id
        name
      }
      applicated
      has_applications
      has_active_applications
    }
    ...on ResultError{
      status_code
      message
    }    
  }
}`;

export const GET_TRUCK_PICTURE = `query getTruckPicture($id: Int!){
    getTruckPicture(
      id: $id
    ){
      __typename
      ...on TruckPicture{
        id
        filename
        mimetype
        encoding
        file
      }
      ...on ResultError{
        status_code
        message
      }
    }
  }
  `;
