import { isPlatform, NavContext } from '@ionic/react';
import {
  Divider,
  Menu,
  MenuItemProps,
  MenuProps,
  Modal,
  SubMenuProps,
} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import Text from 'antd/lib/typography/Text';
import { useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import { IRouteConfig } from '../../../routes';
import { UserService } from '../../../services/user';
import { Authorization, ContextApp, EnumsValues } from '../../../shared';
import { LogoComponent } from './LogoComponent';
import { version } from '../../../../package.json';
import { UserOutlined } from '@ant-design/icons';

export const MainMenu = ({ routes }: { routes: IRouteConfig[] }) => {
  const { functions, setMainMenuCollapsed, user, roleSelected } =
    useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { logout } = UserService();

  const menuProps: MenuProps = {
    theme: 'light',
    mode: 'inline',
  };

  const renderMenuRoutes = useCallback(
    (paramRoutes: IRouteConfig[], level?: number) => {
      return paramRoutes.map((item, index) => {
        if (
          (item.authority &&
            !Authorization.security(functions, item.authority)) ||
          item.hideInMenu
        ) {
          return null;
        }
        if (item.routes) {
          const subMenuProps: SubMenuProps = {};
          if (item.icon) {
            subMenuProps.icon = item.icon;
          }
          subMenuProps.title = item.name;
          return (
            <SubMenu {...subMenuProps}>
              {renderMenuRoutes(item.routes, (level || 0) + 1)}
            </SubMenu>
          );
        } else {
          const menuItemProps: MenuItemProps = {};
          if (item.icon) {
            menuItemProps.icon = item.icon;
          }
          return (
            <Menu.Item
              disabled={
                roleSelected === EnumsValues.UserRoleSystem.Carrier
                  ? !user?.carrier?.validated
                    ? item.requireRegister
                    : false
                  : roleSelected === EnumsValues.UserRoleSystem.Publisher
                  ? !user?.publisher?.validated
                    ? item.requireRegister
                    : false
                  : false
              }
              key={level ? `${level}_${index}` : index}
              {...menuItemProps}
            >
              <Link
                onClick={() => {
                  if (isPlatform('mobile')) {
                    setMainMenuCollapsed(true);
                  }
                }}
                to={item.path}
              >
                {item.name}
              </Link>
            </Menu.Item>
          );
        }
      });
    },
    [functions, user, roleSelected],
  );

  const logoutModal = () => {
    Modal.confirm({
      className: 'modal-confirm-rodar',
      title: '',
      content: '¿Desea salir de Rodar.app?',
      okText: 'Si',
      cancelText: 'No',
      maskClosable: true,
      centered: true,
      onOk: () => logout(),
    });
  };

  return (
    <>
      <LogoComponent />
      <Divider />
      <Menu className="MainMenu" {...menuProps}>
        {roleSelected === EnumsValues.UserRoleSystem.Carrier ? (
          <>
            {!user?.carrier?.validated ? (
              <>
                <Menu.Item
                  onClick={() => {
                    navigate('/app/registerUser');
                  }}
                  key="registerUser"
                  icon={<UserOutlined />}
                >
                  <b>Registrarme</b>
                </Menu.Item>
              </>
            ) : null}
          </>
        ) : roleSelected === EnumsValues.UserRoleSystem.Publisher ? (
          <>
            {!user?.publisher?.validated ? (
              <>
                <>
                  <Menu.Item
                    onClick={() => {
                      navigate('/app/registerUser');
                    }}
                    key="registerUser"
                    icon={<UserOutlined />}
                  >
                    <b>Registrarme</b>
                  </Menu.Item>
                </>
              </>
            ) : null}
          </>
        ) : null}

        {renderMenuRoutes(routes)}
        <Menu.Item
          onClick={() => {
            window.open(
              process.env.REACT_APP_URL_LANDING_PAGE || '/app/home',
              '_blank',
            );
          }}
          key="about_rodar_app"
        >
          Sobre Rodar.app
        </Menu.Item>
        <Divider />
        <Menu.Item
          onClick={() => {
            logoutModal();
          }}
          key="logout"
        >
          Salir
        </Menu.Item>
        <div style={{ marginTop: '64px', paddingLeft: '24px' }}>
          <Text type="secondary">Versión: {version}</Text>
        </div>
      </Menu>
    </>
  );
};
