import { useCallback, useContext, useEffect } from 'react';
import GraphqlService from '../../services/graphql/GraphqlService';
import PushNotificationsContext, {
  NotificationMessageHandler,
} from '../contexts/PushNotificationsContext';
import { EnumsValues } from '../EnumsValues';
import {
  getMessagingToken,
  registerOnMessageHandler,
} from '../firebase-config';

const usePushNotifications = () => {
  const {
    setCanReceivePushNotifications,
    onMessageHandlers,
    setOnMessageHandlers,
  } = useContext(PushNotificationsContext);
  const { Mutation, customRequest } = GraphqlService();

  const sendTokenToServer = useCallback(async (token: string) => {
    const serverResponse: { id: number } = await customRequest({
      mutation: Mutation.registerDevice,
      variables: {
        token,
      },
    });
    if (serverResponse.id) {
      localStorage.setItem(
        EnumsValues.LocalStorageKeys.UserFirebaseMessagingId,
        `${serverResponse.id}`,
      );
    }
    return token;
  }, []);

  const getFirebaseToken = useCallback(async () => {
    try {
      const currentToken = await getMessagingToken();
      if (currentToken) {
        await sendTokenToServer(currentToken);
        setCanReceivePushNotifications(true);
      }
    } catch (err) {
      console.log('An error occurred while retrieving token. ', err);
    }
  }, [sendTokenToServer, setCanReceivePushNotifications]);

  const requestPermission = useCallback(async () => {
    if (Notification.permission === 'denied') {
      return;
    }
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      return getFirebaseToken();
    }
  }, [getFirebaseToken]);

  const initPushNotifications = useCallback(async () => {
    if (!('Notification' in window)) {
      console.warn('Browser does not have support for Notifications');
      return;
    }
    if (!process.env.REACT_APP_FCM_PUBLIC_VAPID_KEY) {
      console.error('Not public vapid key found for FCM, skipping');
      return;
    }
    if (Notification.permission !== 'denied') {
      return requestPermission();
    }
    return getFirebaseToken();
  }, [getFirebaseToken, requestPermission]);

  const addOnMessageHandlers = (newHandlers: NotificationMessageHandler[]) => {
    if (!newHandlers.length) {
      return;
    }
    setOnMessageHandlers((oldMessageHandlers) => {
      const newHandlersArray = [...oldMessageHandlers];
      for (const newHandler of newHandlers) {
        const handlerWithSameKey = newHandlersArray.find(
          (handler) => handler.key === newHandler.key,
        );
        if (handlerWithSameKey) {
          handlerWithSameKey.handler = newHandler.handler;
        } else {
          newHandlersArray.push(newHandler);
        }
      }
      return newHandlersArray;
    });
  };

  const removeOnMessageHandlers = (
    handlersToDelete: NotificationMessageHandler[],
  ) => {
    if (!handlersToDelete.length) {
      return;
    }
    setOnMessageHandlers((oldMessageHandlers) => {
      return oldMessageHandlers.filter(
        (handler) =>
          handlersToDelete.findIndex(
            (handlerToDelete) => handlerToDelete.key === handler.key,
          ) === -1,
      );
    });
  };

  useEffect(() => {
    registerOnMessageHandler((payload) => {
      for (const handler of onMessageHandlers) {
        handler.handler(payload);
      }
    });
  }, [onMessageHandlers]);

  return {
    initPushNotifications,
    addOnMessageHandlers,
    removeOnMessageHandlers,
  };
};

export default usePushNotifications;
