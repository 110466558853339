export const PRODUCTS = `query products(
  $searchText:  String
  $filter: FilterProductInput
  $orderBy: ProductOrderInput
  $skip: Int
  $take: Int
){
  products(
    searchText:$searchText,
    filter:$filter,
    orderBy:$orderBy,
    skip:$skip,
    take:$take,      
  ){
     __typename
     ... on  Product{
       id
       name
     }
     ... on ResultError {
       status_code
       message
     }
   }
 }`;
