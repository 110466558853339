import { renderRoutes } from 'react-router-config';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Layout, notification } from 'antd';
import { Authorization, ContextApp, EnumsValues, Tools } from '../../shared';
import ExceptionManager from '../../components/ExceptionManager';
import ExpiredSession from '../../components/ExpiredSession/ExpiredSession';
import { UserService } from '../../services/user';
import GraphqlService from '../../services/graphql/GraphqlService';
import moment from 'moment-timezone';
import { NavContext } from '@ionic/react';
import { PageLoading } from '@ant-design/pro-layout';
import { LayoutComponent } from './LayoutComponent/LayoutComponent';
import { useCheckPermissionForAppLayout } from '../../shared/hooks/useCheckPermissionForAppLayout';
import usePushNotifications from '../../shared/hooks/usePushNotifications';
import './index.less';
import PushNotificationContext, {
  NotificationMessageHandler,
} from '../../shared/contexts/PushNotificationsContext';
import { CarOutlined } from '@ant-design/icons';
import useCurrentRoute from '../../shared/hooks/useCurrentRoute';

const RegisteredLayoutContent: React.FC<any> = ({ route }: any) => {
  useCheckPermissionForAppLayout();
  const {
    user,
    mainMenuCollapsed,
    setMainMenuCollapsed,
    menuBreakpointCollapsed,
    setMenuBreakpointCollapsed,
    sessionExpired,
    roleSelected,
    setRoleSelected,
    functions,
    setPictureProfileUrl,
    setScrollableContainer,
  } = useContext(ContextApp);
  const { expireSession } = UserService();
  const { leafRoute } = useCurrentRoute();
  const { Query, customRequest } = GraphqlService();
  const { navigate, routeInfo } = useContext(NavContext);
  const { canReceivePushNotifications } = useContext(PushNotificationContext);
  const {
    initPushNotifications,
    addOnMessageHandlers,
    removeOnMessageHandlers,
  } = usePushNotifications();

  useEffect(() => {
    if (user) {
      initPushNotifications();
    }
  }, [user]);

  useEffect(() => {
    const messageHandlers: NotificationMessageHandler[] = [
      {
        key: 'registeredLayoutHandler',
        handler: (payload) => {
          notification.info({
            key: 'pushNotification',
            message: payload.data?.title || payload.notification?.title || '',
            description: payload.data?.body || payload.notification?.body || '',
            icon: <CarOutlined />,
          });
        },
      },
    ];

    if (canReceivePushNotifications) {
      addOnMessageHandlers(messageHandlers);
    }

    return () => {
      if (canReceivePushNotifications) {
        removeOnMessageHandlers(messageHandlers);
      }
    };
  }, [canReceivePushNotifications]);

  const getBreakpointExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed) {
      return {
        marginLeft: 0,
      };
    }
    return {
      marginLeft: 200,
    };
  };

  const { Sider, Content } = Layout;
  useLayoutEffect(() => {
    if (sessionExpired) {
      expireSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    moment.tz.setDefault(
      user?.timezone
        ? user?.timezone
        : process.env.TIMEZONE
        ? process.env.TIMEZONE
        : '',
    );
  }, [user]);

  useEffect(() => {
    if (leafRoute?.requireRegister) {
      if (roleSelected === EnumsValues.UserRoleSystem.Carrier) {
        if (user?.carrier) {
          if (!user.carrier.validated) {
            navigate('/app/registerUser');
          }
        }
      } else if (roleSelected === EnumsValues.UserRoleSystem.Publisher) {
        if (user?.publisher) {
          if (!user.publisher.validated) {
            navigate('/app/registerUser');
          }
        }
      }
    }
  }, [leafRoute]);

  useEffect(() => {
    setRoleSelected(Number(localStorage.getItem('role_selected')));
  }, []);

  const getProfilePicture = () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureRead,
      )
    ) {
      return;
    }
    customRequest({
      query: Query.getProfilePicture,
      variables: { id: user?.profile_picture_id },
    })
      .then((data) => {
        setPictureProfileUrl(
          Tools.getUrlOfBase64File({
            mimetype: data.mimetype,
            fileBase64: data.file,
          }),
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (user?.profile_picture_id) {
      getProfilePicture();
    }
  }, [user]);

  const getBackdropExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed && !mainMenuCollapsed) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'none',
    };
  };

  useEffect(() => {
    if (leafRoute) {
      if (!user) {
        setRoleSelected(undefined);
        if (leafRoute.isStorablePath) {
          localStorage.setItem(
            EnumsValues.LocalStorageKeys.BackUrlAfterLogin,
            routeInfo ? routeInfo.pathname : '',
          );
        }
        localStorage.removeItem('role_selected');
        navigate('/login');
      }
    }
  }, [leafRoute]);

  useEffect(() => {
    setScrollableContainer(document.getElementById('scrollableDiv'));
  }, []);

  return (
    <>
      {!sessionExpired ? (
        <>
          {user ? (
            <LayoutComponent route={route} tabBar={true}>
              <>
                <ExceptionManager>
                  {renderRoutes(route.routes)}
                </ExceptionManager>
              </>
            </LayoutComponent>
          ) : (
            <PageLoading />
          )}
        </>
      ) : (
        <>
          <Layout className="RegisteredLayout">
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              collapsible
              collapsed={mainMenuCollapsed}
              trigger={null}
              onBreakpoint={(broken) => {
                setMainMenuCollapsed(() => broken);
                setMenuBreakpointCollapsed(() => broken);
              }}
              theme="light"
            ></Sider>
            <Layout
              style={
                mainMenuCollapsed
                  ? {
                      marginLeft: 0,
                    }
                  : getBreakpointExpandedStyles()
              }
            >
              <div
                className="backdrop-content"
                onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
                style={getBackdropExpandedStyles()}
              ></div>

              <Content className="site-layout">
                <ExpiredSession />
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

const RegisteredLayout = (props: any) => {
  const [onMessageHandlers, setOnMessageHandlers] = useState<
    NotificationMessageHandler[]
  >([]);
  const [canReceivePushNotifications, setCanReceivePushNotifications] =
    useState(false);
  return (
    <PushNotificationContext.Provider
      value={{
        canReceivePushNotifications,
        onMessageHandlers,
        setCanReceivePushNotifications,
        setOnMessageHandlers,
      }}
    >
      <RegisteredLayoutContent {...props} />
    </PushNotificationContext.Provider>
  );
};

export default RegisteredLayout;
