import { useContext } from 'react';
import { Button, Result } from 'antd';
import './ExpiredSession.less';
import { UserService } from '../../services/user';
import { ContextApp } from '../../shared';
import { NavContext } from '@ionic/react';

const ExpiredSession = (props: any) => {
  const { setSessionExpired } = useContext(ContextApp);
  const { logout } = UserService();
  const { navigate } = useContext(NavContext);
  return (
    <Result
      status="403"
      title="401"
      subTitle="Se te ha vencido la sesión. Debes volver a loguearte."
      extra={
        <Button
          type="primary"
          onClick={() => {
            setSessionExpired(false);
            logout();
            navigate('/login');
          }}
          key="console"
        >
          Volver al login
        </Button>
      }
    />
  );
};

export default ExpiredSession;
