export const CARRIERS_BACKOFFICE = `
query gridCarrier(
  $filter:  FilterCarrierGridInput, 
  $orderBy:CarrierGridOrderInput, 
  $skip: Int, 
  $take: Int
){
  gridCarrier(
    filter: $filter, 
    orderBy:$orderBy, 
    skip:$skip, 
    take:$take
  ){
    __typename
    ...on Carrier{
      id
      user{
        id
        phone
        carrier_id
        business_name
        publisher_id
      }
      its
      validated
      applications_data{
        pending_application
        accepted_application
        rejected_application
        completed_application         
      }
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
