export const NOTIFICATIONS = `
  query notifications(
    $filter: FilterNotificationInput,
    $searchText: String,
    $orderBy: NotificationOrderInput,
    $skip: Int,
    $take: Int,
  ) {
    notifications(
      filter: $filter,
      searchText: $searchText,
      orderBy: $orderBy,
      skip: $skip,
      take: $take,
    ) {
      __typename
      ... on Notification {
        id
        its
        recipient_user_id
        recipient_role_id
        title
        description
        notification_type_id
        carrier_id
        publisher_id
        trip_id
        application_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
