import { MessagePayload } from 'firebase/messaging';
import { createContext, SetStateAction, Dispatch } from 'react';

export type NotificationMessageHandler = {
  key: string;
  handler: (payload: MessagePayload) => void;
};

export interface PushNotificationsContext {
  onMessageHandlers: NotificationMessageHandler[];
  setOnMessageHandlers: Dispatch<SetStateAction<NotificationMessageHandler[]>>;
  canReceivePushNotifications: boolean;
  setCanReceivePushNotifications: Dispatch<SetStateAction<boolean>>;
}

const pushNotificationsContext = createContext<PushNotificationsContext>({
  onMessageHandlers: [],
  setOnMessageHandlers: () => [],
  canReceivePushNotifications: false,
  setCanReceivePushNotifications: () => false,
});

export default pushNotificationsContext;
