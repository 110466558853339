export const CREATE_USER = `
  mutation createUser(
    $input:  CreateUserInput!
  ) {
    createUser(
      input: $input
    ) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const UPDATE_USER = `
mutation updateUser(
	$input: UpdateUserInput!,
  $inputProfile: CreateProfileInput
  ) {
    updateUser(
      input: $input,
      inputProfile: $inputProfile,
    ) {
      __typename
      ... on User {
        id
        username
        firstname
        lastname
        email
        profile_id
        profile{
          phone
          timezone
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const DELETE_USER = `
  mutation deleteUser($id: Int!) {
    deleteUser(id: $id) {
      __typename
      ... on User {
        id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const RESET_PASSWORD = `
  mutation resetPassword(
    $email: String,
  ){
    resetPassword(email:$email){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const SET_PASSWORD = `
  mutation setPassword(
    $token: String!,
    $password: String!,
  )
  {
    setPassword(
        token:$token, 
        password:$password
    ){
      __typename
      ...on RecoveryResult{
        result
      }
      ...on ResultError{
        status_code
        message
}
    }
  }
`;

export const UPSERT_USER_ROLE = `
  mutation upsertUserRole($input: UpsertUserRoleInput!){
  upsertUserRole(input:$input)
  {
    __typename
    ...on UserRole{
      id
      role {
        id
        name
      }
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_USER_FIREBASE = `
mutation upsertUserFirebase(
  $input: UpsertUserFirebaseInput!
){
  upsertUserFirebase(
    input:$input    
  ){
    __typename
    ...on User{
            uid
    }
    ...on ResultError{
      status_code
      message
    }    
  }
}
`;

export const UPSERT_USER_PROFILE = `
mutation upsertProfileData($input:  UpsertUserProfileDataInput!,
  $profile_picture: ProfilePictureInput){
  upsertProfileData(
    input: $input
    profile_picture:$profile_picture
  ){
    __typename
    ...on User{
      email
      business_name
      contact_phone
      cuit_cuil
      registered_profile
    }
    
    ...on ResultError{
      status_code
      message
    }
    
  }
}`;

export const CREATE_PROFILE_PICTURE = `
mutation createProfilePicture(
  $file:  Upload!
){
  createProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const UPDATE_PROFILE_PICTURE = `
mutation updateProfilePicture($file: Upload!){
  updateProfilePicture(
    file: $file,
  ){
    __typename
    ...on ProfilePicture{
      id
      filename
      mimetype
      encoding
      file
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
