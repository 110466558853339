export const CREATE_APPLICATION = `
mutation createApplication($input: CreateApplicationInput!) {
  createApplication(input: $input) {
    __typename
    ... on Application {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_APPLICATION = `
mutation updateApplication($input: UpdateApplicationInput!) {
  updateApplication(input: $input) {
    __typename
    ... on Application {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_APPLICATION = `
mutation deleteApplication($id: Int!) {
  deleteApplication(id: $id) {
    __typename
    ... on Application {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const ACCEPT_REJECT_APPLICATION = `
mutation acceptRejectApplication($application_id: Int!, $current_status: Boolean!) {
  acceptRejectApplication(application_id:$application_id,current_status:$current_status) {
    __typename
    ... on Application {
      id
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const COMPLETED_APPLICATION = `
mutation completedApplication($input: CreateEvaluationInput!) {
  completedApplication(input: $input) {
    __typename
    ... on  Application{
     id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
