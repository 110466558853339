import { renderRoutes } from 'react-router-config';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { Layout } from 'antd';
import '../registered/index.less';
import { Authorization, ContextApp, EnumsValues, Tools } from '../../shared';
import ExceptionManager from '../../components/ExceptionManager';
import ExpiredSession from '../../components/ExpiredSession/ExpiredSession';
import { UserService } from '../../services/user';
import GraphqlService from '../../services/graphql/GraphqlService';
import moment from 'moment-timezone';
import { NavContext } from '@ionic/react';
import { PageLoading } from '@ant-design/pro-layout';
import { LayoutComponent } from '../registered/LayoutComponent/LayoutComponent';
import { useCheckPermissionForBackOfficeLayout } from '../../shared/hooks/useCheckPermissionForBackOfficeLayout';

const RegisteredAdminLayout: React.FC = ({ route }: any) => {
  useCheckPermissionForBackOfficeLayout();
  const {
    user,
    mainMenuCollapsed,
    setMainMenuCollapsed,
    menuBreakpointCollapsed,
    setMenuBreakpointCollapsed,
    sessionExpired,
    functions,
    setPictureProfileUrl,
  } = useContext(ContextApp);
  const { expireSession } = UserService();
  const { Query, customRequest } = GraphqlService();
  const { navigate } = useContext(NavContext);

  const getBreakpointExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed) {
      return {
        marginLeft: 0,
      };
    }
    return {
      marginLeft: 200,
    };
  };

  const { Sider, Content } = Layout;
  useLayoutEffect(() => {
    if (sessionExpired) {
      expireSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    moment.tz.setDefault(
      user?.timezone
        ? user?.timezone
        : process.env.TIMEZONE
        ? process.env.TIMEZONE
        : '',
    );
  }, [user]);

  const getProfilePicture = () => {
    if (
      !Authorization.security(
        functions,
        EnumsValues.Functions.ProfilePictureRead,
      )
    ) {
      return;
    }
    customRequest({
      query: Query.getProfilePicture,
      variables: { id: user?.profile_picture_id },
    })
      .then((data) => {
        setPictureProfileUrl(
          Tools.getUrlOfBase64File({
            mimetype: data.mimetype,
            fileBase64: data.file,
          }),
        );
      })
      .catch(() => {});
  };
  useEffect(() => {
    if (user?.profile_picture_id) {
      getProfilePicture();
    }
  }, [user]);

  const getBackdropExpandedStyles = (): React.CSSProperties | undefined => {
    if (menuBreakpointCollapsed && !mainMenuCollapsed) {
      return {
        display: 'block',
      };
    }
    return {
      display: 'none',
    };
  };

  if (!user) {
    navigate('/admin18378');
  }

  return (
    <>
      {!sessionExpired ? (
        <>
          {user ? (
            <LayoutComponent route={route} tabBar={true}>
              <>
                <ExceptionManager>
                  {renderRoutes(route.routes)}
                </ExceptionManager>
              </>
            </LayoutComponent>
          ) : (
            <PageLoading />
          )}
        </>
      ) : (
        <>
          <Layout className="RegisteredLayout">
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              collapsible
              collapsed={mainMenuCollapsed}
              trigger={null}
              onBreakpoint={(broken) => {
                setMainMenuCollapsed(() => broken);
                setMenuBreakpointCollapsed(() => broken);
              }}
              theme="light"
            ></Sider>
            <Layout
              style={
                mainMenuCollapsed
                  ? {
                      marginLeft: 0,
                    }
                  : getBreakpointExpandedStyles()
              }
            >
              <div
                className="backdrop-content"
                onClick={() => setMainMenuCollapsed(!mainMenuCollapsed)}
                style={getBackdropExpandedStyles()}
              ></div>

              <Content className="site-layout">
                <ExpiredSession />
              </Content>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
};

export default RegisteredAdminLayout;
