export const APPLICATION = `
query application($id: Int!) {
  application(id: $id) {
    __typename
    ... on Application {
      id
      carrier_id
      experience_value{
        id
        name
        comment
      }
      application_status {
        id
        name
      }
      trip {
        id
        publisher_id
        product_id
        product {
          id
          name
        }
        quantity_tn
        unload_type {
          id
          name
        }
        origin {
          id
          name
          department {
            province {
              id
              name
            }
          }
        }
        destination {
          id
          name
          department {
            province {
              id
              name
            }
          }
        }
        distance_km
        load_date
        unload_date
      }
      application_truck{
        truck_id
        truck{
          id
          mark
          model
          max_load_tn
          year
        	truck_picture{
            id
          }
          truck_type{
            name
          }
          license_plate{
            value
            configuration_license_plate{
              label
            }
          }
        }
      }
      max_load
      price_km
      its
      due_date
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const APPLICATION_LIST = `
query applicationsForPublisher(
  $filter: FilterApplicationsForPublisherInput!
  $orderBy: ApplicationOrderInput
  $skip: Int
  $take: Int
) {
  applicationsForPublisher(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on ApplicationForPublisher {
      id
      its
      carrier_business_name
      max_load
      due_date
      price_km
      carrier_id
      carrier{
        user{
          phone
        }
      }
      application_truck{
        truck {
        id
        max_load_tn
        license_plate{
          id
          value
          configuration_license_plate{
            id
            label
            position
          }
        }
        mark
        model
      }
      }
      user_reputation {
        experience {
          name
          id
        }
        count_by_experience
      }
    }

    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const APPLICATION_FOR_CARRIER = `
query applicationsForCarrier(
  $filter: FilterApplicationInput   
  $skip: Int
  $take: Int
  $orderBy: ApplicationOrderInput
) {
  applicationsForCarrier(
    filter: $filter
    skip: $skip
    take: $take
    orderBy: $orderBy
  ) {
    __typename
    ... on Application {
      id
      its
      trip {
        id
        product {
          name
        }
        quantity_tn
        unload_type {
          name
        }
        destination{
          name
          department{
            name
            province{
              name
            }
          }
        }
        origin {
          name
          department {
            name
            province {
              name
            }
          }
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const AVERAGE_PRICE_APPLICATION = `
query averagePriceApplication($carrier_id: Int!) {
  averagePriceApplication(carrier_id: $carrier_id) {
    __typename
    ... on ApplicationAveragePrice {
      applications_number
      average
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const QUANTITY_OF_TRIPS_CONFIRMED = `
query quantityOfTripsConfirmed{
  quantityOfTripsConfirmed{
    __typename
    ...on ApplicationsQuantity{
      quantity
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;
