export const PUBLISHER_NEWS = `
  query newsPublisher {
    newsPublisher {
      __typename
      ... on PublisherNews {
        active_trip
        pending_application
        completed_application
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const PUBLISHERS_BACKOFFICE = `
query gridPublisher(
  $filter: FilterPublisherGridInput
  $orderBy: PublisherGridOrderInput
  $skip: Int
  $take: Int
) {
  gridPublisher(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Publisher {
      id
      user {
        id
        publisher_id
        business_name
        phone
        carrier_id
      }
      its
      validated
      applications_data {
        accepted_application
        rejected_application
        completed_application
      }
      trips_data {
        active_trip
        inactive_trip
      }      
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
